import type { ComponentProps } from 'react'

import { useLingui } from '@lingui/react'

import type { I18nLanguage } from '@twisto/base'
import { Link } from '@twisto/components/atoms/link'
import { Typography } from '@twisto/components/atoms/typography'
import type { IconComponent } from '@twisto/icons'
import { getCalendarDateAndTime } from '@twisto/utils'

import * as styles from './notification-message.styles'

type Props = {
  icon: IconComponent
  title: string
  subtitle: string
  date: string
  seen: boolean
  actionNeeded: boolean
  linkProps: ComponentProps<typeof Link>
}

export const NotificationMessage = ({
  icon,
  title,
  subtitle,
  date,
  seen,
  actionNeeded,
  linkProps,
}: Props) => {
  const {
    i18n: { locale },
  } = useLingui()

  const Icon = icon

  const containerStyles = [
    styles.container,
    actionNeeded && styles.actionNeeded.container,
  ]
  const iconWrapperStyles = [
    styles.iconWrapper,
    actionNeeded && styles.actionNeeded.iconWrapper,
  ]
  const iconStyles = [
    styles.icon,
    seen && styles.seen.icon,
    actionNeeded && styles.actionNeeded.icon,
  ]
  const titleStyles = [
    styles.title,
    seen && styles.seen.title,
    actionNeeded && styles.actionNeeded.title,
  ]
  const subtitleStyles = [
    styles.subtitle,
    seen && styles.seen.subtitle,
    actionNeeded && styles.actionNeeded.subtitle,
  ]

  const calendarDate = getCalendarDateAndTime(date, locale as I18nLanguage)

  return (
    <Link {...linkProps}>
      <div css={containerStyles}>
        <div css={iconWrapperStyles}>
          <Icon css={iconStyles} />
        </div>
        <Typography className="notification-title" css={titleStyles}>
          <span css={styles.truncated}>{title}</span>
          <span>{calendarDate}</span>
        </Typography>
        <Typography className="notification-subtitle" css={subtitleStyles}>
          {subtitle}
        </Typography>
      </div>
    </Link>
  )
}
