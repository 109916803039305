import type { ReactElement, ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import Portal from '@reach/portal'

import { DropdownMenu } from './dropdown-menu'
import * as styles from './dropdown.styles'

type Props = {
  label: ReactElement | string
  children: ReactNode
  onChange?: (opened: boolean) => void
  hideTriangle?: boolean
  horizontalAlignment?: 'left' | 'right'
  verticalAlignment?: 'bottom' | 'center'
  className?: string
  dropdownDataTestId?: string
  openerDataTestId?: string
  closed?: boolean
}

export const DropDown = ({
  children,
  label,
  onChange,
  hideTriangle = false,
  horizontalAlignment = 'left',
  verticalAlignment = 'bottom',
  closed = false,
  className,
  dropdownDataTestId = 'dropdown',
  openerDataTestId = 'dropdown-opener',
}: Props) => {
  const [opened, setOpened] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (closed) {
      setOpened(false)
    }
  }, [closed])

  useEffect(() => {
    if (onChange) {
      onChange(opened)
    }
  }, [opened, onChange])

  const handleClickOutside = useCallback(() => {
    if (opened) {
      setOpened(false)
    }
  }, [setOpened, opened])

  return (
    <>
      <button
        ref={ref}
        className={className}
        css={styles.root}
        data-testid={openerDataTestId}
        onClick={() => {
          setOpened((opened) => !opened)
        }}
      >
        {label}
      </button>
      <Portal>
        {opened && (
          <DropdownMenu
            dataTestId={dropdownDataTestId}
            hideTriangle={hideTriangle}
            horizontalAlignment={horizontalAlignment}
            parentRef={ref}
            verticalAlignment={verticalAlignment}
            onClickOutside={handleClickOutside}
          >
            {children}
          </DropdownMenu>
        )}
      </Portal>
    </>
  )
}
