import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = (theme: Theme, noBackground: boolean) => css`
  height: ${styles.size[72]};
  padding: 0 ${styles.size[16]} 0 ${styles.size[24]};
  display: grid;
  grid-template-columns: 1fr auto 1fr; // the customer section title has the most space
  align-items: center;

  ${!noBackground &&
  `
  background: ${theme.palette.background.primary};
  `}
`

export const border = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.palette.neutral[300]};
`

export const closeButton = css`
  top: unset;
`
