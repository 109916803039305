import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { gradients } from '@twisto/icons'
import * as styles from '@twisto/styles'

export const container = (theme: Theme) => css`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    'icon title'
    'icon subtitle';
  column-gap: ${styles.size[16]};
  row-gap: ${styles.size[4]};
  align-items: center;
  width: 100%;
  padding: ${styles.size[24]};
  border-radius: ${styles.borderRadius.md};
  background-color: ${theme.palette.neutral[200]};
  transition: background-color 250ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.palette.neutral[300]};

    svg {
      transform: scale(1.33);
    }

    .notification-title {
      color: ${theme.palette.neutral[800]};
    }

    .notification-subtitle {
      color: ${theme.palette.neutral[900]};
    }
  }
`

export const iconWrapper = (theme: Theme) => css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.neutral[300]};
  width: 48px;
  height: 48px;
  border-radius: 24px;
  grid-area: icon;
`

export const icon = (theme: Theme) => css`
  width: 24px;
  height: 24px;
  fill: ${theme.palette.neutral[800]};
  transition: all 250ms ease-in-out;
`

export const title = (theme: Theme) => css`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content;
  column-gap: ${styles.size[24]};
  grid-area: title;
  align-self: end;

  font-size: ${styles.fontSize.xxxs};
  font-weight: 500;
  line-height: 1.5;
  color: ${theme.palette.neutral[800]};
  transition: color 250ms ease-in-out;
  text-transform: uppercase;
`

export const truncated = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const subtitle = (theme: Theme) => css`
  font-size: ${styles.fontSize.xxs};
  font-weight: 400;
  line-height: 1.58;
  color: ${theme.palette.neutral[900]};
  transition: color 250ms ease-in-out;
  align-self: start;
  grid-area: subtitle;
`

export const seen = {
  icon: (theme: Theme) => css`
    fill: ${theme.palette.neutral[700]};
  `,
  title: (theme: Theme) => css`
    color: ${theme.palette.neutral[700]};
  `,
  subtitle: (theme: Theme) => css`
    color: ${theme.palette.neutral[800]};
  `,
}

export const actionNeeded = {
  container: (theme: Theme) => css`
    background-color: ${theme.palette.warning[100]};

    &:hover {
      background-color: ${theme.palette.warning[200]};

      .notification-title {
        color: ${theme.palette.warning[500]};
      }
    }
  `,
  iconWrapper: (theme: Theme) => css`
    background-color: ${theme.palette.warning[200]};
  `,
  icon: css`
    fill: url(#${gradients.warning});
  `,
  title: (theme: Theme) => css`
    color: ${theme.palette.warning[500]};
  `,
  subtitle: (theme: Theme) => css`
    color: ${theme.palette.neutral[900]};
  `,
}
