import { Trans } from '@lingui/react'
import { useAtom } from 'jotai'
import { isNil } from 'ramda'

import type { SectionAvailability } from '@twisto/api-graphql'
import {
  useLogoutMutation,
  useSectionsAvailabilityQuery,
  useUniqaTravelInsuranceQuery,
  useUserAccountDetailQuery,
} from '@twisto/api-graphql'
import { pushToDataLayer } from '@twisto/base'
import { FormattedCurrencyNumber } from '@twisto/components/atoms/format'
import type { NavigationLink } from '@twisto/components/molecules/mobile-navigation'
import {
  BillFilledIcon,
  BillOutlineIcon,
  CardFilledIcon,
  CardOutlineIcon,
  CashFilledIcon,
  CashOutlineIcon,
  ChartOutlineIcon,
  CreditsOutlineIcon,
  ExpensesFilledIcon,
  ExpensesOutlineIcon,
  FileOutlineIcon,
  FxRateFilledIcon,
  FxRateOutlineIcon,
  GiftFilledIcon,
  GiftOutlineIcon,
  HomeFilledIcon,
  HomeIcon,
  InstallmentsFilledIcon,
  InstallmentsOutlineIcon,
  InsuranceFilledIcon,
  InsuranceOutlineIcon,
  LogoutIcon,
  MessageIcon,
  SettingsOutlineIcon,
  ShoppingFilledIcon,
  ShoppingOutlineIcon,
  SnapFilledIcon,
  SnapOutlineIcon,
  TooltipFilledIcon,
  TooltipOutlineIcon,
  TwistoOutlineIcon,
} from '@twisto/icons'
import { numberOfCredits } from '@twisto/locale'

import { useCustomerConfig } from 'customer/components/customer-config'
import type { LivechatMainButton } from 'customer/components/live-chat'
import { toggleLivechat } from 'customer/components/live-chat'
import { appRoutes } from 'customer/routes'

export type DesktopComponent =
  | 'SIDEBAR'
  | 'ACCOUNT_DROPDOWN'
  | 'MORE_DROPDOWN'
  | 'ALL'
  | 'NONE'

export type MobileGroup = 'OTHER' | 'FEATURE' | 'ACCOUNT'

type GetNavigationLinksFilter = {
  desktopComponentFilter?: DesktopComponent
}

type CustomerNavigationLink = NavigationLink & {
  availability?: SectionAvailability
  mobileGroup: MobileGroup
  desktopComponent: DesktopComponent
}

// Links and availibility is based on ticket:
// https://twisto.myjetbrains.com/youtrack/agiles/119-34/120-347?issue=WAP-768
export const useGetNavigationGroups = ({
  desktopComponentFilter = 'SIDEBAR',
}: GetNavigationLinksFilter) => {
  const { externalTwistoExchangeRateUrl, twistoSupportUrl, urls } =
    useCustomerConfig()
  const { data: sectionsData } = useSectionsAvailabilityQuery()

  const [logout] = useLogoutMutation({
    onCompleted: (data) => {
      if (!isNil(data.logout.success)) {
        pushToDataLayer({ event: 'logout' })
        window.location.assign(appRoutes.auth.login.path)
      }
    },
  })
  const { data, loading } = useUserAccountDetailQuery()
  const currentPlan =
    data?.account?.__typename === 'PremiumAccount' && data.account.plan

  const getPlanSublabel = () => {
    const currentPlanInfo = data?.availablePlans.find(
      ({ plan }) => plan === currentPlan
    )

    if (currentPlanInfo?.plan === 'BASIC') {
      return currentPlanInfo.name
    }

    const monthlyFee = !isNil(currentPlanInfo?.price) && (
      <FormattedCurrencyNumber value={currentPlanInfo.price} />
    )

    return (
      <Trans
        id="navigation.planChange.subLabel"
        values={{
          planName: currentPlanInfo?.name,
          monthlyFee,
        }}
      />
    )
  }

  const [isLivechatOpen, setLivechatState] = useAtom(toggleLivechat)

  const openChat = () => {
    if (isLivechatOpen) {
      const mainButton: LivechatMainButton = document.querySelector(
        '[data-component-name="mainButton"]'
      )

      if (mainButton) {
        mainButton.style.display = 'block'
        mainButton.click()
      }

      return
    }

    setLivechatState(!isLivechatOpen)
  }

  const { data: insuranceData } = useUniqaTravelInsuranceQuery()
  const insuranceState = insuranceData?.insurance.uniqaTravelInsurance?.state

  // NextJS doesn't use /app prefix in the router, we need to remove it to find active section
  const getLinkToSection = (route: string) => route.replace('/app', '')
  const sectionAvailability = sectionsData?.sections

  const navigationLinks: CustomerNavigationLink[] = [
    {
      label: <Trans id="navigation.section.overview" />,
      to: getLinkToSection(appRoutes.overview.dashboard.path),
      icon: HomeIcon,
      activeIcon: HomeFilledIcon,
      desktopComponent: 'SIDEBAR',
      mobileGroup: 'FEATURE',
      availability: sectionAvailability?.overview.availability,
    },
    {
      label: <Trans id="navigation.section.expenses" />,
      to: getLinkToSection(appRoutes.expenses.path),
      icon: ExpensesOutlineIcon,
      activeIcon: ExpensesFilledIcon,
      desktopComponent: 'SIDEBAR',
      mobileGroup: 'FEATURE',
      availability: sectionAvailability?.expenses.availability,
    },
    {
      label: <Trans id="navigation.section.shopping" />,
      to: getLinkToSection(appRoutes.shopping.path),
      icon: ShoppingOutlineIcon,
      activeIcon: ShoppingFilledIcon,
      desktopComponent: 'SIDEBAR',
      availability: sectionAvailability?.shopping.availability,
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.section.card" />,
      to: getLinkToSection(appRoutes.card.overview.path),
      icon: CardOutlineIcon,
      activeIcon: CardFilledIcon,
      desktopComponent: 'SIDEBAR',
      availability: sectionAvailability?.card.availability,
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.section.installments" />,
      to: getLinkToSection(appRoutes.installments.overview.path),
      icon: InstallmentsOutlineIcon,
      activeIcon: InstallmentsFilledIcon,
      desktopComponent: 'SIDEBAR',
      availability: sectionAvailability?.installments.availability,
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.section.twistoCash" />,
      to: getLinkToSection(appRoutes.cash.overview.path),
      icon: CashOutlineIcon,
      activeIcon: CashFilledIcon,
      desktopComponent: 'SIDEBAR',
      availability: sectionAvailability?.cash.availability,
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.section.billing" />,
      to: getLinkToSection(appRoutes.billing.dashboard.getPath()),
      icon: BillOutlineIcon,
      activeIcon: BillFilledIcon,
      availability: sectionAvailability?.billing.availability,
      desktopComponent: 'SIDEBAR',
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.section.twistoSnap" />,
      to: getLinkToSection(appRoutes.snap.path),
      icon: SnapOutlineIcon,
      activeIcon: SnapFilledIcon,
      desktopComponent: 'SIDEBAR',
      availability: sectionAvailability?.snap.availability,
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.account.planChange" />,
      subLabel: getPlanSublabel(),
      to: getLinkToSection(appRoutes.planChange.path),
      icon: TwistoOutlineIcon,
      desktopComponent: 'ACCOUNT_DROPDOWN',
      availability: sectionAvailability?.planChange.availability,
      mobileGroup: 'ACCOUNT',
    },
    {
      label: <Trans id="navigation.account.travelInsurance" />,
      to: getLinkToSection(appRoutes.insurance.overview.path),
      icon: InsuranceOutlineIcon,
      activeIcon: InsuranceFilledIcon,
      availability: sectionAvailability?.travelInsurance.availability,
      desktopComponent: 'SIDEBAR',
      mobileGroup: 'FEATURE',
      ...(insuranceState === 'ACTIVE'
        ? {
            iconColor: 'primary',
          }
        : {
            subLabel: (
              <Trans id="navigation.account.travelInsurance.inactive" />
            ),
            iconColor: 'warning',
          }),
    },
    {
      label: <Trans id="navigation.section.referral" />,
      subLabel: <Trans id="navigation.section.referral.sublabel" />,
      to: getLinkToSection(appRoutes.referral.path),
      icon: GiftOutlineIcon,
      activeIcon: GiftFilledIcon,
      availability: sectionAvailability?.referral.availability,
      desktopComponent: 'MORE_DROPDOWN',
      mobileGroup: 'FEATURE',
    },
    {
      label: <Trans id="navigation.account.credits" />,
      subLabel: numberOfCredits(data?.account?.creditAmount ?? 0),
      to: getLinkToSection(appRoutes.credits.path),
      icon: CreditsOutlineIcon,
      availability: sectionAvailability?.credits.availability,
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'ACCOUNT',
    },
    {
      label: <Trans id="navigation.account.myLimits" />,
      to: getLinkToSection(appRoutes.limits.overview.path),
      icon: ChartOutlineIcon,
      availability: sectionAvailability?.limit.availability,
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'ACCOUNT',
    },
    {
      label: <Trans id="navigation.account.settings" />,
      to: getLinkToSection(appRoutes.settings.index.path),
      icon: SettingsOutlineIcon,
      availability: sectionAvailability?.settings.availability,
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'ACCOUNT',
    },
    {
      label: <Trans id="navigation.account.contracts" />,
      ...('documents' in urls
        ? {
            href: urls.documents,
          }
        : { to: getLinkToSection(appRoutes.documents.index.path) }),
      icon: FileOutlineIcon,
      availability: sectionAvailability?.documents.availability,
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'ACCOUNT',
    },
    {
      label: <Trans id="navigation.section.exchangeRate" />,
      href: externalTwistoExchangeRateUrl,
      target: '_blank',
      icon: FxRateOutlineIcon,
      activeIcon: FxRateFilledIcon,
      availability: sectionAvailability?.exchangeRates.availability,
      desktopComponent: 'MORE_DROPDOWN',
      mobileGroup: 'OTHER',
    },
    {
      label: <Trans id="navigation.section.help" />,
      href: twistoSupportUrl,
      target: '_blank',
      icon: TooltipOutlineIcon,
      activeIcon: TooltipFilledIcon,
      availability: 'APPLICABLE', // ALWAYS AVAILABLE
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'OTHER',
    },
    {
      label: <Trans id="navigation.account.chat" />,
      subLabel: <Trans id="navigation.account.chat.sublabel" />,
      onClick: openChat,
      icon: MessageIcon,
      activeIcon: MessageIcon,
      availability: 'APPLICABLE', // ALWAYS AVAILABLE
      desktopComponent: 'NONE',
      mobileGroup: 'OTHER',
    },
    {
      label: <Trans id="navigation.account.logout" />,
      onClick: logout,
      icon: LogoutIcon,
      availability: 'APPLICABLE', // ALWAYS AVAILABLE
      iconColor: 'error',
      desktopComponent: 'ACCOUNT_DROPDOWN',
      mobileGroup: 'OTHER',
    },
  ]

  const grouped = navigationLinks
    .filter((link) => link.availability !== 'FORBIDDEN')
    .reduce<Record<MobileGroup, NavigationLink[]>>(
      (group, obj) => {
        const groupLabel = obj.mobileGroup

        if (isNil(group[groupLabel])) {
          // eslint-disable-next-line no-param-reassign
          group[groupLabel] = []
        }
        // Add object to list for given key's value
        group[groupLabel].push(obj)

        return group
      },
      {} as Record<MobileGroup, NavigationLink[]>
    )

  return {
    links: navigationLinks.filter(
      ({ desktopComponent, availability }) =>
        (desktopComponentFilter === 'ALL' ||
          desktopComponent === desktopComponentFilter) &&
        availability !== 'FORBIDDEN'
    ),
    grouped,
    loading,
  }
}

export const useGetNavigationLinks = ({
  desktopComponentFilter = 'SIDEBAR',
}: GetNavigationLinksFilter) => {
  const { links, grouped, loading } = useGetNavigationGroups({
    desktopComponentFilter,
  })

  return {
    links,
    grouped,
    loading,
  }
}
