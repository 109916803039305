import type { NotificationDataFragment } from '@twisto/api-graphql'
import { AppError } from '@twisto/components/molecules/error-boundary'

import { appRoutes } from 'customer/routes'

import { NotificationMessage } from '../notification-message'

import { getLinkProps, getNotificationIcon } from './notification.helpers'

type Props = {
  data: NotificationDataFragment
}

export const Notification = ({ data }: Props) => {
  switch (data.__typename) {
    case 'Notification': {
      return (
        <NotificationMessage
          actionNeeded={data.actionNeeded}
          date={data.dateCreated}
          icon={getNotificationIcon(data.type)}
          linkProps={getLinkProps(data)}
          seen={data.seen}
          subtitle={data.subtitle}
          title={data.title}
        />
      )
    }
    case 'Banner':
    default: {
      throw new AppError(`There is no component for ${data.__typename}`, {
        reloadButton: false,
        toDashboardButton: appRoutes.overview.dashboard.path,
      })
    }
  }
}
