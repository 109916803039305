import type { ReactElement, ReactNode } from 'react'
import { useRef } from 'react'

import type { IconCircleColors } from '@twisto/components/atoms/icon-circle'
import type { HomeIcon } from '@twisto/icons'

import * as styles from './mobile-navigation.styles'

export type NavigationLink = {
  label: ReactElement
  to?: string
  href?: string
  target?: string
  onClick?: () => void
  icon: typeof HomeIcon
  activeIcon?: typeof HomeIcon
  iconColor?: IconCircleColors
  available?: boolean | undefined
  subLabel?: ReactElement | string
}

type Props = {
  header?: ReactElement
  moreLinks?: ReactNode
  moreVisible?: boolean
  children?: ReactNode
}

export const MobileNavigation = ({
  children,
  moreLinks,
  header,
  moreVisible,
}: Props) => {
  const container = useRef<HTMLDivElement>(null)

  return (
    <nav data-testid="mobile-navigation">
      {moreVisible === true && (
        <div css={styles.moreLinksOverlay}>
          {header && (
            /* Extra div needed to protect header from flex shrinking */
            <div>{header}</div>
          )}
          <div css={styles.moreLinksWrapper}>{moreLinks}</div>
        </div>
      )}
      <div css={styles.placeholder}>
        <div ref={container} css={styles.root}>
          <div css={styles.buttonRowContainer}>{children}</div>
        </div>
      </div>
    </nav>
  )
}
