import { useRef, useState } from 'react'

import useResizeObserver from 'use-resize-observer/polyfilled'

import { useNotificationsQuery } from '@twisto/api-graphql'
import { Avatar } from '@twisto/components/atoms/avatar'
import { DropDown } from '@twisto/components/atoms/dropdown'
import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { Notification } from '@twisto/components/atoms/notification'
import { useScrollPosition } from '@twisto/hooks'
import { NotificationIcon } from '@twisto/icons'

import { useCustomerConfig } from 'customer/components/customer-config'

import { NotificationList } from './notification-list'
import * as styles from './notifications-dropdown.styles'

const maxUnread = 99

export const NotificationsDropdown = () => {
  const [opened, setOpened] = useState(false)
  const { data } = useNotificationsQuery()
  const { featureFlags } = useCustomerConfig()
  const unreadCount = Math.min(data?.notifications.unreadCount ?? 0, maxUnread)

  const container = useRef<HTMLDivElement>(null)
  const scroll = useScrollPosition(container.current)
  const { height: containerHeight = 0 } = useResizeObserver<HTMLDivElement>({
    ref: container,
  })

  const content = useRef<HTMLDivElement>(null)
  const { height: contentHeight = 0 } = useResizeObserver<HTMLDivElement>({
    ref: content,
  })

  const gradientBuffer = 20
  const showTopGradient = scroll > gradientBuffer
  const showBottomGradient =
    scroll + gradientBuffer < contentHeight - containerHeight

  if (featureFlags.notifications === false) {
    return null
  }

  return (
    <DropDown
      dropdownDataTestId="notifications-dropdown"
      label={
        <Avatar
          noBorder
          styles={{
            root: styles.avatarRootOverrides,
            subIcon: styles.badge,
          }}
          subIcon={
            unreadCount > 0 ? <Notification value={unreadCount} /> : undefined
          }
        >
          <IconCircle
            css={[
              styles.notificationsIcon,
              opened && styles.notificationsIconActive,
            ]}
            icon={NotificationIcon}
          />
        </Avatar>
      }
      openerDataTestId="notifications-dropdown-opener"
      onChange={setOpened}
    >
      <>
        <div ref={container} css={styles.dropdownContent}>
          <div ref={content}>
            <NotificationList />
          </div>
        </div>
        <div
          css={[styles.topGradient, showTopGradient && styles.gradientVisible]}
        />
        <div
          css={[
            styles.bottomGradient,
            showBottomGradient && styles.gradientVisible,
          ]}
        />
      </>
    </DropDown>
  )
}
