import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

const WITHOUT_NUMBER_SIZE = 8
const MOBILE_SIZE = 16
const DESKTOP_SIZE = 20

export const root = (theme: Theme) => css`
  width: ${styles.size[MOBILE_SIZE]};
  height: ${styles.size[MOBILE_SIZE]};
  border-radius: ${styles.borderRadius.circle};
  background: ${theme.palette.error[500]};
  user-select: none;

  ${styles.breakpoint.up('sm')} {
    width: ${styles.size[DESKTOP_SIZE]};
    height: ${styles.size[DESKTOP_SIZE]};
  }
`

export const value = (theme: Theme) => css`
  color: ${theme.palette.action['000']};
  line-height: ${styles.size[MOBILE_SIZE]};

  ${styles.breakpoint.up('sm')} {
    line-height: ${styles.size[DESKTOP_SIZE]};
  }
`

export const withoutNumber = css`
  width: ${styles.size[WITHOUT_NUMBER_SIZE]};
  height: ${styles.size[WITHOUT_NUMBER_SIZE]};

  ${styles.breakpoint.up('sm')} {
    width: ${styles.size[WITHOUT_NUMBER_SIZE]};
    height: ${styles.size[WITHOUT_NUMBER_SIZE]};
  }
`
