import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, breakpoint, size } from '@twisto/styles'

export const root = css`
  padding: ${size[20]} ${size[24]};
`

export const icon = css`
  border-radius: ${borderRadius.sm};
  width: ${size[24]};
  height: ${size[24]};

  ${breakpoint.up('sm')} {
    border-radius: ${borderRadius.sm};
  }
`

export const label = (theme: Theme, width: number) => css`
  margin: 0 0 0 ${size[16]};
  width: ${width}px;
`
