import type { ReactNode, RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

import { clamp, isNil } from 'ramda'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { Paper } from '../paper'

import * as styles from './dropdown.styles'

const edgeSpace = 16
const topSpacing = 12
const divisor = 2

type Props = {
  children: ReactNode
  onClickOutside: () => void
  verticalAlignment?: 'bottom' | 'center'
  horizontalAlignment?: 'left' | 'right'
  hideTriangle: boolean
  parentRef: RefObject<HTMLButtonElement> | null
  dataTestId?: string
}

export const DropdownMenu = ({
  children,
  onClickOutside,
  verticalAlignment = 'bottom',
  horizontalAlignment = 'left',
  hideTriangle,
  parentRef,
  dataTestId = 'dropdown-menu',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver<HTMLDivElement>({ ref })
  const [rect, setRect] = useState<DOMRect | null>(
    parentRef?.current?.getBoundingClientRect() ?? null
  )
  const scrolledBy = useRef<number>(document.body.scrollTop || window.scrollY)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
      const dropdownToggle = parentRef?.current ?? null
      const dropdown = ref.current
      const target = e.target as Node

      if (
        dropdownToggle &&
        !dropdownToggle.contains(target) &&
        dropdown &&
        !dropdown.contains(target)
      ) {
        onClickOutside()
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [onClickOutside, parentRef])

  useEffect(() => {
    const handleResize = () => {
      setRect(parentRef?.current?.getBoundingClientRect() ?? null)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [parentRef])

  if (!rect) {
    return null
  }

  const menuWidth = width ?? 0
  const menuHeight = height ?? 0

  const getVerticalPosition = () => {
    switch (verticalAlignment) {
      case 'bottom':
        return rect.bottom + topSpacing + scrolledBy.current
      case 'center':
        return clamp(
          edgeSpace,
          Math.max(window.innerHeight - menuHeight / divisor, edgeSpace),
          rect.bottom +
            scrolledBy.current -
            menuHeight / divisor -
            rect.height / divisor
        )
    }
  }

  const getHorizontalPosition = () => {
    // if vertical is center we want to show dropdown next to its trigget button
    if (verticalAlignment === 'center') {
      return clamp(
        edgeSpace,
        Math.max(window.innerWidth - menuWidth - edgeSpace, edgeSpace),
        horizontalAlignment === 'right'
          ? rect.right + edgeSpace
          : rect.left - edgeSpace - menuWidth
      )
    }

    // else align dropdown to left or right
    return clamp(
      edgeSpace,
      Math.max(window.innerWidth - menuWidth - edgeSpace, edgeSpace),
      horizontalAlignment === 'left'
        ? rect.right - menuWidth + edgeSpace
        : rect.left - edgeSpace
    )
  }

  const menuPositionLeft = getHorizontalPosition()
  const menuPositionTop = getVerticalPosition()
  const arrowPositionLeft =
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    rect.left - 6 + rect.width / 2 - menuPositionLeft

  return (
    <Paper
      ref={ref}
      noMargin
      noPadding
      css={(theme) => [
        styles.dropdownMenu,
        !hideTriangle &&
          verticalAlignment !== 'center' &&
          styles.dropdownTriangle(theme, arrowPositionLeft),
        !isNil(width) && styles.dropdownMenuFadeIn,
      ]}
      style={{
        left: menuPositionLeft,
        top: menuPositionTop,
      }}
    >
      <div css={styles.childrenWrapper} data-testid={dataTestId}>
        {children}
      </div>
    </Paper>
  )
}
