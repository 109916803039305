import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = (theme: Theme) => css`
  padding: ${styles.size[24]};
  padding-bottom: ${styles.size[20]};
  border-bottom: 1px solid ${theme.palette.neutral[300]};
`
