import { useState } from 'react'

import { Trans } from '@lingui/react'
import { splitAt } from 'ramda'

import { DropDown, DropDownHeader } from '@twisto/components/atoms/dropdown'
import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { Action } from '@twisto/components/molecules/action'
import {
  NavigationItem,
  NavigationItemSkeleton,
  NavigationStaticItem,
} from '@twisto/components/molecules/navigation-item'
import { MoreFilledIcon, MoreOutlineIcon } from '@twisto/icons'

import { useGetNavigationLinks } from '../../get-navigation-links.hook'
import { NavigationActionList } from '../navigation-action-list'

import * as styles from './desktop-main-navigation-list.styles'
import { DesktopNavigationFeaturesDivider } from './desktop-navigation-features-divider'

const MAX_COUNT_OF_ITEMS_IN_LIST = 7
type Props = {
  isCollapsed: boolean
}

export const DesktopMainNavigationList = ({ isCollapsed }: Props) => {
  const [closeDropdown, setCloseDropdown] = useState(false)
  const { links, loading } = useGetNavigationLinks({})
  const { links: moreDropDownLinks } = useGetNavigationLinks({
    desktopComponentFilter: 'MORE_DROPDOWN',
  })

  // take links which doesn't fit into sidebar and move them into more dropdown
  const [linksInMainNavigation, sidebarLinksInMoreDropDown] = splitAt(
    MAX_COUNT_OF_ITEMS_IN_LIST,
    links
  )
  // first take these links which doesn't fit into sidebar
  // and add links which should always be within dropdown
  const linksInMoreDropDown = [
    ...sidebarLinksInMoreDropDown,
    ...moreDropDownLinks,
  ]

  if (loading) {
    return (
      <div>
        <NavigationItemSkeleton isCollapsed={isCollapsed} width={96} />
        <NavigationItemSkeleton isCollapsed={isCollapsed} width={120} />
        <NavigationItemSkeleton isCollapsed={isCollapsed} width={80} />
        <NavigationItemSkeleton isCollapsed={isCollapsed} width={96} />
      </div>
    )
  }

  const applicableItems = linksInMainNavigation.filter(
    ({ availability }) => availability === 'APPLICABLE'
  )
  const visibleLockedSections = linksInMainNavigation.filter(
    ({ availability }) => availability === 'VISIBLE'
  )

  return (
    <>
      {applicableItems.map(
        ({ onClick, to, href, label, icon, activeIcon, target }, index) => (
          <NavigationItem
            key={index}
            activeIconComponent={activeIcon}
            collapsed={isCollapsed}
            href={href}
            iconComponent={icon}
            label={label}
            target={target}
            to={to}
            onClick={onClick}
          />
        )
      )}

      {/* VISIBLE BUT LOCKED SECTIONS ARE LISTED BELOW */}
      {visibleLockedSections.length > 0 && (
        <DesktopNavigationFeaturesDivider isCollapsed={isCollapsed} />
      )}

      {visibleLockedSections.map(
        ({ onClick, to, href, label, icon, activeIcon, target }, index) => (
          <NavigationItem
            key={index}
            activeIconComponent={activeIcon}
            collapsed={isCollapsed}
            href={href}
            iconComponent={icon}
            label={label}
            target={target}
            to={to}
            onClick={onClick}
          />
        )
      )}

      {/* "More" Dropdown in side navigation */}
      {linksInMoreDropDown.length > 0 && (
        <DropDown
          closed={closeDropdown}
          css={styles.moreMenuDropDownRoot}
          dropdownDataTestId="more-navigation-dropdown"
          horizontalAlignment="right"
          label={
            <NavigationStaticItem
              activeIconComponent={MoreFilledIcon}
              collapsed={isCollapsed}
              iconComponent={MoreOutlineIcon}
              label={<Trans id="navigation.section.more" />}
              onClick={() => setCloseDropdown(false)}
            />
          }
          verticalAlignment="center"
        >
          <DropDownHeader
            label={<Trans id="navigation.section.more.dropdown" />}
          />
          <div css={styles.moreMenu}>
            <NavigationActionList>
              {linksInMoreDropDown.map((link, index) => (
                <Action
                  key={index}
                  caption={link.label}
                  href={link.href}
                  icon={
                    <IconCircle color="primary" icon={link.icon} size="40" />
                  }
                  label={link.subLabel}
                  target={link.target}
                  to={link.to}
                  onClick={() => setCloseDropdown(true)}
                />
              ))}
            </NavigationActionList>
          </div>
        </DropDown>
      )}
    </>
  )
}
