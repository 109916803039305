import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { size } from '@twisto/styles'

export const avatarIcon = css`
  margin-right: ${size[12]};
`

export const styledEndAdornment = styled('div')`
  margin-left: ${size[16]};
`

export const disabled = (theme: Theme) => css`
  color: ${theme.palette.neutral[600]};
`

export const icon = css`
  border-width: 0;
`
