import { useEffect, useRef } from 'react'

import { Trans } from '@lingui/react'
import { complement, isNil } from 'ramda'

import type { NotificationDataFragment } from '@twisto/api-graphql'
import {
  NotificationsDocument,
  useMarkNotificationAsSeenMutation,
  useNotificationsQuery,
} from '@twisto/api-graphql'
import { InfiniteScroll } from '@twisto/components/atoms/infinite-scroll'
import { Skeleton } from '@twisto/components/atoms/skeleton'
import { Typography } from '@twisto/components/atoms/typography'

import { EmptyNotifications } from '../empty-notifications'

import { NotificationInViewWrapper } from './notification-inview-wrapper'
import * as styles from './notification-list.styles'

export const NotificationList = () => {
  /** notifications query and pagination */
  const notificationsCount = useRef(0)
  const abortController = useRef(new AbortController())
  const { data, loading, fetchMore } = useNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    context: {
      fetchOptions: { signal: abortController.current.signal },
      overrideNonFieldErrorHandler: true,
    },
  })

  const notifications = data?.notifications.edges
    .filter(complement(isNil))
    .map((edge) => edge?.node as unknown as NotificationDataFragment)
  notificationsCount.current = notifications?.length ?? 0
  const pageInfo = data?.notifications.pageInfo
  const sortedNotifications = notifications?.reduce(
    (acc, notification) => {
      let key: keyof typeof acc = notification.seen ? 'read' : 'unread'

      if (
        notification.__typename === 'Notification' &&
        notification.actionNeeded
      ) {
        key = 'actionNeeded'
      }

      return {
        ...acc,
        [key]: [...acc[key], notification],
      }
    },
    {
      actionNeeded: [] as NotificationDataFragment[],
      unread: [] as NotificationDataFragment[],
      read: [] as NotificationDataFragment[],
    }
  )

  const loadMore = () =>
    !loading &&
    !isNil(pageInfo) &&
    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    })

  /** marking notifications as seen logic */
  const markedAsSeen = useRef<NotificationDataFragment[]>([])
  const [markAsSeen] = useMarkNotificationAsSeenMutation({
    update: (cache, result) => {
      const resultData =
        result.data?.markNotificationsAsSeen.success?.notifications

      if (!isNil(resultData)) {
        cache.writeQuery({
          query: NotificationsDocument,
          data: { notifications: resultData },
        })
      }
    },
    optimisticResponse: ({ ids }) => {
      // update notifications unread count before it's returned from backend
      const seenWithoutActionable = markedAsSeen.current.reduce(
        (count, notification) => {
          if (
            !(
              notification.__typename === 'Notification' &&
              notification.actionNeeded
            ) &&
            !notification.seen
          ) {
            return count + 1
          }

          return count
        },
        0
      )
      const updatedUnreadCount =
        (data?.notifications.unreadCount ?? ids.length) - seenWithoutActionable

      return {
        markNotificationsAsSeen: {
          __typename: 'MarkNotificationsAsSeenPayload',
          errors: null,
          success: {
            __typename: 'MarkNotificationsAsSeenSuccess',
            notifications: {
              __typename: 'NotificationConnection',
              unreadCount: updatedUnreadCount,
              edges: [],
              pageInfo: {
                __typename: 'PageInfo',
                hasNextPage: true,
                endCursor: null,
              },
            },
          },
        },
      }
    },
  })

  useEffect(
    () => () => {
      if (markedAsSeen.current.length) {
        markAsSeen({
          variables: {
            ids: markedAsSeen.current.map(({ id }) => id),
            first: notificationsCount.current,
          },
        })

        // aborts notifications queries in progress to avoid race conditions in updating unreadCount
        abortController.current.abort()
      }
    },
    [markAsSeen]
  )

  const handleNotificationSeen = (notification: NotificationDataFragment) => {
    markedAsSeen.current = [...markedAsSeen.current, notification]
  }

  const hasMore = data?.notifications.pageInfo.hasNextPage !== false

  return (
    <>
      <Typography fontWeight="medium" variant="b1">
        <Trans id="notifications.title" />
      </Typography>
      <InfiniteScroll
        dataLength={notifications?.length ?? 0}
        hasMore={hasMore}
        loadMore={loadMore}
        loader={
          <>
            <Skeleton css={styles.skeleton} />
            <Skeleton css={styles.skeleton} />
            <Skeleton css={styles.skeleton} />
          </>
        }
      >
        {!loading && !hasMore && notifications?.length === 0 ? (
          <EmptyNotifications />
        ) : (
          <>
            {(sortedNotifications?.actionNeeded.length ?? 0) > 0 && (
              <Typography
                css={styles.sectionTitle}
                fontWeight="medium"
                variant="b2"
              >
                <Trans id="notifications.section.actionRequired" />
              </Typography>
            )}
            {sortedNotifications?.actionNeeded.map((notification) => (
              <NotificationInViewWrapper
                key={notification.id}
                notification={notification}
                withInViewListener={!notification.seen}
                onSeen={handleNotificationSeen}
              />
            ))}
            {(sortedNotifications?.unread.length ?? 0) > 0 && (
              <Typography
                css={styles.sectionTitle}
                fontWeight="medium"
                variant="b2"
              >
                <Trans id="notifications.section.unread" />
              </Typography>
            )}
            {sortedNotifications?.unread.map((notification) => (
              <NotificationInViewWrapper
                key={notification.id}
                notification={notification}
                withInViewListener={!notification.seen}
                onSeen={handleNotificationSeen}
              />
            ))}
            {(sortedNotifications?.read.length ?? 0) > 0 && (
              <Typography
                css={styles.sectionTitle}
                fontWeight="medium"
                variant="b2"
              >
                <Trans id="notifications.section.read" />
              </Typography>
            )}
            {sortedNotifications?.read.map((notification) => (
              <NotificationInViewWrapper
                key={notification.id}
                notification={notification}
                withInViewListener={!notification.seen}
                onSeen={handleNotificationSeen}
              />
            ))}
          </>
        )}
      </InfiniteScroll>
    </>
  )
}
