import type { ReactElement, ReactNode } from 'react'

import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './mobile-navigation.styles'

type Props = {
  label: ReactElement
  children?: ReactNode
}

export const MobileNavigationMoreGroup = ({ label, children }: Props) => (
  <div css={styles.group} data-testid="mobile-navigation-more-group">
    <Typography css={styles.groupLabel} fontWeight="medium" variant="b1">
      {label}
    </Typography>
    {children}
  </div>
)
