import { useState } from 'react'

import { Trans } from '@lingui/react'

import { useUserAccountDetailQuery } from '@twisto/api-graphql'
import { DropDown, DropDownHeader } from '@twisto/components/atoms/dropdown'
import { Flex } from '@twisto/components/atoms/flex'
import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { Skeleton } from '@twisto/components/atoms/skeleton'
import { Typography } from '@twisto/components/atoms/typography'
import { UserAvatar } from '@twisto/components/atoms/user-avatar'
import { Action } from '@twisto/components/molecules/action'
import { ChevronDownIcon } from '@twisto/icons'

import { useGetNavigationLinks } from '../../get-navigation-links.hook'
import { NavigationActionList } from '../navigation-action-list'

import * as styles from './user-account-button.styles'

export const UserAccountButton = () => {
  const { links } = useGetNavigationLinks({
    desktopComponentFilter: 'ACCOUNT_DROPDOWN',
  })
  const { data, loading } = useUserAccountDetailQuery()
  const [closeDropdown, setCloseDropdown] = useState(false)

  if (!data || loading) {
    return (
      <div css={styles.skeletonWrapper}>
        <Skeleton css={styles.avatarSkeleton} />
        <Skeleton variant="row" />
      </div>
    )
  }

  const account = data.account
  const customerName =
    account?.__typename === 'PremiumAccount' ? account.name : undefined
  const customerEmail = account?.email

  const label = customerName ?? customerEmail

  return (
    <DropDown
      hideTriangle
      closed={closeDropdown}
      dropdownDataTestId="account-dropdown-menu"
      label={
        <Flex
          alignItems="center"
          css={(theme) => [styles.root(theme), styles.spacing]}
          onClick={() => setCloseDropdown(false)}
        >
          <UserAvatar
            css={styles.avatar}
            name={customerName}
            size="sm"
            src={data.account?.avatar?.url}
          />
          <Typography
            color="textSecondary"
            css={styles.label}
            data-testid="user-name"
            variant="b2"
          >
            {label}
          </Typography>
          <ChevronDownIcon size="18" />
        </Flex>
      }
    >
      <div css={styles.dropdownMenu}>
        <DropDownHeader
          label={<Trans id="navigation.userAccountButton.dropdownLabel" />}
        />
        <div css={styles.linkSection}>
          <NavigationActionList>
            {links.map(({ onClick, ...link }, index) => (
              <Action
                key={index}
                caption={link.label}
                href={link.href}
                icon={
                  <IconCircle
                    color={link.iconColor ?? 'primary'}
                    icon={link.icon}
                  />
                }
                label={link.subLabel}
                to={link.to}
                onClick={() => {
                  setCloseDropdown(true)
                  onClick?.()
                }}
              />
            ))}
          </NavigationActionList>
        </div>
      </div>
    </DropDown>
  )
}
