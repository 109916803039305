import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const sectionTitle = css`
  margin: 24px 0 12px;
`

export const notification = css`
  margin: ${styles.size[8]} 0 ${styles.size[8]};
`

export const skeleton = css`
  margin: ${styles.size[8]} 0 ${styles.size[8]};
  border-radius: ${styles.borderRadius.md};

  ${styles.breakpoint.up('sm')} {
    border-radius: ${styles.borderRadius.md};
  }

  &:after {
    padding-top: 20%;
  }
`
