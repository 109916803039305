import { Flex } from '@twisto/components/atoms/flex'
import { Skeleton } from '@twisto/components/atoms/skeleton'

import * as styles from './navigation-item-skeleton.styles'

type Props = {
  isCollapsed: boolean
  width: number
}
export const NavigationItemSkeleton = ({ width, isCollapsed }: Props) => (
  <Flex css={styles.root}>
    <Skeleton css={styles.icon} variant="default" />
    {!isCollapsed && (
      <Skeleton css={(theme) => styles.label(theme, width)} variant="row" />
    )}
  </Flex>
)
