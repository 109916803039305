import { useEffect } from 'react'

import { atom, useAtom } from 'jotai'

import { useCustomerEmailsQuery } from '@twisto/api-graphql'
import { useAppConfig } from '@twisto/base/app-config'

import { useCustomerConfig } from 'customer/components/customer-config'

export type LivechatMainButton = HTMLElement | null

export const toggleLivechat = atom(false)

export const LiveChat = () => {
  const { data, loading } = useCustomerEmailsQuery()
  const { DAKTELA_GUI_SERVER, support } = useAppConfig()
  const { featureFlags } = useCustomerConfig()
  const { daktelaGuiToken } = support
  const [isLivechatOpen, setLivechatState] = useAtom(toggleLivechat)

  useEffect(() => {
    if (loading) {
      return
    }

    if (!featureFlags.omnichannel) {
      return
    }

    if (window.daktelaGui) {
      return
    }

    const daktelaGuiConfig = {
      server: DAKTELA_GUI_SERVER,
      accessToken: daktelaGuiToken,
      settings: {
        inputs: {
          email: { defaultValue: data?.customerEmails.primary.email },
        },
      },
      fnAfterInit: (display: boolean) => {
        if (display && isLivechatOpen) {
          const mainButton: LivechatMainButton = document.querySelector(
            '[data-component-name="mainButton"]'
          )
          const hideButton: LivechatMainButton = document.querySelector(
            '#daktela-web-header-hide'
          )

          if (mainButton) {
            mainButton.click()
          }

          if (hideButton) {
            hideButton.addEventListener('click', () => {
              if (mainButton) {
                mainButton.style.display = 'none'
              }
            })
          }
        }
      },
    }

    const daktelaScriptEl = document.createElement('script')
    daktelaScriptEl.src = `${daktelaGuiConfig.server}external/web/web.js`
    daktelaScriptEl.type = 'text/javascript'
    daktelaScriptEl.async = true

    daktelaScriptEl.onload = function () {
      if (window.daktelaGui) {
        // eslint-disable-next-line new-cap
        const daktelaWeb = new window.daktelaGui()
        daktelaWeb.init(daktelaGuiConfig)
      }
    }

    document.head.appendChild(daktelaScriptEl)
  }, [
    data,
    loading,
    DAKTELA_GUI_SERVER,
    daktelaGuiToken,
    featureFlags,
    isLivechatOpen,
    setLivechatState,
  ])

  return null
}
