import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = css`
  border-radius: ${styles.borderRadius.md};
  padding: ${styles.size[20]};

  ${styles.breakpoint.up('sm')} {
    padding: ${styles.size[24]};
  }
`

export const icon = css`
  font-size: ${styles.size[48]};
  margin-bottom: ${styles.size[24]};
`

export const header = css`
  margin-bottom: ${styles.size[8]};
`
