import type { JSX } from '@emotion/react/jsx-runtime'

import type { IconComponent } from '@twisto/icons'

import { NavigationItemContent } from './navigation-item-content'
import * as styles from './navigation-item.styles'

type Props = {
  label: JSX.Element | string
  iconComponent: IconComponent
  activeIconComponent?: IconComponent
  collapsed: boolean
  onClick?: () => void
}

export const NavigationStaticItem = ({
  label,
  collapsed,
  iconComponent,
  activeIconComponent,
  onClick,
}: Props) => (
  <span
    css={() => [styles.link]}
    data-testid="navigation-item"
    onClick={onClick}
  >
    <NavigationItemContent
      activeIconComponent={activeIconComponent}
      collapsed={collapsed}
      iconComponent={iconComponent}
      label={label}
    />
  </span>
)
