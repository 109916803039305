import { css } from '@emotion/react'

export const column = css`
  flex-direction: column;
`

export const row = css`
  flex-direction: row;
`

export const grow = css`
  flex-grow: 1;
`

export const noShrink = css`
  flex-shrink: 0;
`

export const noWrap = css`
  flex-wrap: nowrap;
`

export const wrap = css`
  flex-wrap: wrap;
`

export const alignStart = css`
  align-items: flex-start;
`

export const alignCenter = css`
  align-items: center;
`

export const alignEnd = css`
  align-items: flex-end;
`

export const justifyStart = css`
  justify-content: flex-start;
`

export const justifyCenter = css`
  justify-content: center;
`

export const justifySpaceBetween = css`
  justify-content: space-between;
`

export const justifyEnd = css`
  justify-content: flex-end;
`
export const columnWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`
