import type { ComponentProps } from 'react'

import { isNil } from 'ramda'

import { BaseButton } from '@twisto/components/atoms/button'

import { ActionContent } from './action-content'
import * as styles from './action.styles'

type Props = {
  onClick?: () => void
  href?: string
  noPadding?: boolean
  to?: string
  replace?: boolean
  disabled?: boolean
  'data-testid'?: string
} & ComponentProps<typeof BaseButton> &
  ComponentProps<typeof ActionContent>

export const Action = ({
  icon,
  endAdornment,
  caption,
  label,
  onClick,
  href,
  noPadding = false,
  to,
  disabled = false,
  ...props
}: Props) => {
  if (isNil(to) && isNil(href) && isNil(onClick)) {
    return (
      <div
        className={props.className}
        css={[styles.root, noPadding && styles.noPadding]}
        data-testid={props['data-testid'] ?? 'action'}
      >
        <ActionContent
          caption={caption}
          disabled={disabled}
          endAdornment={endAdornment}
          icon={icon}
          label={label}
        />
      </div>
    )
  }

  return (
    <BaseButton
      css={[
        styles.root,
        !disabled && styles.clickable,
        noPadding && styles.noPadding,
      ]}
      data-testid="action"
      disabled={disabled}
      href={!disabled ? href : undefined}
      to={!disabled ? to : undefined}
      onClick={onClick}
      {...props}
    >
      <ActionContent
        caption={caption}
        disabled={disabled}
        endAdornment={endAdornment}
        icon={icon}
        label={label}
      />
    </BaseButton>
  )
}
