import type { ComponentProps } from 'react'

import { isNil } from 'ramda'

import type { Notification, NotificationType } from '@twisto/api-graphql'
import type { Link } from '@twisto/components/atoms/link'
import {
  CardCrossedIcon,
  CreditsOutlineIcon,
  DecreaseIcon,
  ExpensesOutlineIcon,
  InstallmentsOutlineIcon,
  PhonePayIcon,
  SecurityIcon,
  SplitIcon,
  TopUpIcon,
  TwistoOutlineIcon,
  TwistoPayOutlineIcon,
} from '@twisto/icons'

import { appRoutes } from 'customer/routes'

export const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case 'CARD_ADD_TO_APPLE_PAY':
    case 'CARD_ADD_TO_GOOGLE_PAY':
      return PhonePayIcon
    case 'CARD_PAYMENT_REJECTED':
      return CardCrossedIcon
    case 'CREDITS_RECEIVED_CARD_REFUND':
    case 'CREDITS_RECEIVED_GENERAL':
      return CreditsOutlineIcon
    case 'EXPENSES_FX_MONTH_SAVED':
    case 'EXPENSES_LAST_MONTH_CATEGORY':
      return ExpensesOutlineIcon
    case 'INSTALLMENTS_OFFER':
      return InstallmentsOutlineIcon
    case 'LIMIT_INCREASE_ACCOUNT':
    case 'LIMIT_INCREASE_INSTALLMENTS':
    case 'LIMIT_INCREASE_ACCOUNT_AND_INSTALLMENTS':
      return TopUpIcon
    case 'LIMIT_DECREASE_ACCOUNT':
    case 'LIMIT_DECREASE_INSTALLMENTS':
    case 'LIMIT_DECREASE_ANTI_USURY':
      return DecreaseIcon
    case 'PAYMENT_MARQETA_3DS':
      return SecurityIcon
    case 'SPLIT_ACCEPT':
    case 'SPLIT_DENY':
    case 'SPLIT_INCOMING':
    case 'SPLIT_OUTGOING_ACCEPTED':
    case 'SPLIT_OUTGOING_DECLINED':
    case 'SPLIT_OUTGOING_EXPIRED':
      return SplitIcon
    case 'TWISTO_PAY_REJECTED':
      return TwistoPayOutlineIcon
    default:
      return TwistoOutlineIcon
  }
}

export const getLinkProps = ({
  detail,
  detailId,
  type,
}: Pick<Notification, 'detail' | 'detailId' | 'type'>): ComponentProps<
  typeof Link
> => {
  if (!isNil(detailId)) {
    switch (type) {
      case 'LIMIT_INCREASE_ACCOUNT':
      case 'LIMIT_INCREASE_INSTALLMENTS':
      case 'LIMIT_INCREASE_ACCOUNT_AND_INSTALLMENTS':
      case 'LIMIT_DECREASE_ACCOUNT':
      case 'LIMIT_DECREASE_INSTALLMENTS':
      case 'LIMIT_OFFER_ACCOUNT':
      case 'LIMIT_OFFER_ACCOUNT_AND_INSTALLMENTS':
      case 'LIMIT_OFFER_INSTALLMENTS':
      case 'LIMIT_DECREASE_ANTI_USURY':
        return {
          to: appRoutes.overview.limitOffer.getPath(detailId),
        }
      case 'SPLIT_INCOMING':
        return {
          to: appRoutes.overview.splitRequest.getPath(detailId),
        }
    }
  }

  return {
    href: detail,
  }
}
