import type { ReactElement } from 'react'
import { useState } from 'react'

import { Trans } from '@lingui/react'
import { equals } from 'ramda'

import { ActionList } from '@twisto/components/molecules/action'
import {
  MobileNavigation,
  MobileNavigationMoreGroup,
} from '@twisto/components/molecules/mobile-navigation'
import { MobileNavigationLink } from '@twisto/components/molecules/mobile-navigation/mobile-navigation-link'
import { MobileNavigationMoreLink } from '@twisto/components/molecules/mobile-navigation/mobile-navigation-more-link'
import { MoreFilledIcon, MoreOutlineIcon } from '@twisto/icons'

import type { MobileGroup } from '../../get-navigation-links.hook'
import {
  useGetNavigationGroups,
  useGetNavigationLinks,
} from '../../get-navigation-links.hook'
import { CustomerMobileAppBar } from '../customer-mobile-app-bar'

const COUNT_OF_ITEMS_IN_MAIN_MENU = 4
export const CustomerMobileNavigation = () => {
  const [moreVisible, setMoreVisible] = useState(false)
  const { links } = useGetNavigationLinks({ desktopComponentFilter: 'ALL' })
  const { grouped } = useGetNavigationGroups({ desktopComponentFilter: 'ALL' })

  const labels: Record<MobileGroup, ReactElement> = {
    FEATURE: <Trans id="navigation.mobile.linkGroup.features" />,
    OTHER: <Trans id="navigation.mobile.linkGroup.other" />,
    ACCOUNT: <Trans id="navigation.mobile.linkGroup.account" />,
  }

  const applicableLinks = links.filter(
    (linkItem) =>
      linkItem.availability === 'APPLICABLE' &&
      linkItem.mobileGroup === 'FEATURE'
  )
  const visibleLinks = applicableLinks.slice(
    0,
    Math.min(COUNT_OF_ITEMS_IN_MAIN_MENU, applicableLinks.length)
  )

  const moreGroups = Object.entries(grouped)
    .map(([key, links]) => ({
      label: labels[key as MobileGroup],
      links: links.filter(
        (link) => !visibleLinks.find((visibleLink) => equals(link, visibleLink))
      ),
    }))
    .filter(({ links }) => links.length !== 0)

  return (
    <MobileNavigation
      header={
        <CustomerMobileAppBar
          sectionName={<Trans id="navigation.section.more.dropdown" />}
        />
      }
      moreLinks={
        <>
          {moreGroups.map(({ label, links }, index) => (
            <MobileNavigationMoreGroup key={index} label={label}>
              <ActionList>
                {links.map((linkProps, index) => (
                  <MobileNavigationMoreLink
                    key={index}
                    onClick={() => {
                      setMoreVisible(false)
                      linkProps.onClick?.()
                    }}
                    {...linkProps}
                  />
                ))}
              </ActionList>
            </MobileNavigationMoreGroup>
          ))}
        </>
      }
      moreVisible={moreVisible}
    >
      {/* Items visible in main navigation */}
      {visibleLinks.map((linkProps, index) => (
        <MobileNavigationLink
          key={index}
          active={moreVisible ? false : undefined}
          onClick={() => {
            setMoreVisible(false)
          }}
          {...linkProps}
        />
      ))}
      {/* More button */}
      <MobileNavigationLink
        active={moreVisible}
        icon={moreVisible ? MoreFilledIcon : MoreOutlineIcon}
        label={<Trans id="navigation.section.more" />}
        onClick={() => setMoreVisible((visible) => !visible)}
      />
    </MobileNavigation>
  )
}
