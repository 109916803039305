import { Flex } from '@twisto/components/atoms/flex'
import { Typography } from '@twisto/components/atoms/typography'
import type { IconComponent } from '@twisto/icons'

import * as styles from './navigation-item-content.styles'

type Props = {
  label: JSX.Element | string
  iconComponent: IconComponent
  activeIconComponent?: IconComponent
  collapsed: boolean
}

export const NavigationItemContent = ({
  label,
  iconComponent,
  activeIconComponent,
  collapsed,
}: Props) => {
  const IconComponent = iconComponent
  const ActiveIconComponent = activeIconComponent ?? iconComponent

  return (
    <Flex alignItems="center" css={styles.root}>
      <IconComponent className="defaultIcon" color="inherit" size="24" />
      <ActiveIconComponent className="activeIcon" color="inherit" size="24" />
      <Typography
        css={[styles.label, collapsed && styles.labelHidden]}
        fontWeight="regular"
        variant="b2"
      >
        {label}
      </Typography>
    </Flex>
  )
}
