import type { Dispatch } from 'react'
import { createContext, useContext, useEffect } from 'react'

type ContextType = {
  setSectionName: Dispatch<string | null>
  setBackTo: Dispatch<string | null>
  setClosePath: Dispatch<string | null>
}

const CustomerAppLayoutContext = createContext<ContextType>({
  setSectionName: () => null,
  setBackTo: () => null,
  setClosePath: () => null,
})

export const CustomerAppLayoutContextProvider =
  CustomerAppLayoutContext.Provider

type Args = {
  sectionName?: string | null
  backTo?: string | null
  closePath?: string | null
}

export const useCustomerLayout = ({ sectionName, backTo, closePath }: Args) => {
  const { setSectionName, setBackTo, setClosePath } = useContext(
    CustomerAppLayoutContext
  )

  useEffect(() => {
    if (sectionName !== undefined) {
      setSectionName(sectionName)
    }
    if (backTo !== undefined) {
      setBackTo(backTo)
    }
    setClosePath(closePath ?? null)
  }, [backTo, sectionName, closePath, setBackTo, setSectionName, setClosePath])

  return ({ sectionName, backTo, closePath }: Args) => {
    if (sectionName !== undefined) {
      setSectionName(sectionName)
    }
    if (backTo !== undefined) {
      setBackTo(backTo)
    }
    setClosePath(closePath ?? null)
  }
}
