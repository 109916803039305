import { Trans } from '@lingui/react'

import {
  Headline,
  HeadlineIcon,
  HeadlineSubtitle,
  HeadlineTitle,
} from '@twisto/components/atoms/headline'
import { BigNotificationCrossedIcon } from '@twisto/icons'

import * as styles from './empty-notifications.styles'

export const EmptyNotifications = () => (
  <Headline css={styles.root}>
    <HeadlineIcon>
      <BigNotificationCrossedIcon color="warning" height="100%" width="100%" />
    </HeadlineIcon>
    <HeadlineTitle>
      <Trans id="notifications.empty.title" />
    </HeadlineTitle>
    <HeadlineSubtitle>
      <Trans id="notifications.empty.perex" />
    </HeadlineSubtitle>
  </Headline>
)
