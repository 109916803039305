import type { ReactNode } from 'react'

import { ActionList } from '@twisto/components/molecules/action'

import * as styles from './navigation-action-list.styles'

type Props = {
  children?: ReactNode[]
  className?: string
}

export const NavigationActionList = ({ children, className }: Props) => (
  <ActionList className={className} css={styles.container}>
    {children}
  </ActionList>
)
