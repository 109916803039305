import type { ReactElement } from 'react'

import { subIcon } from './avatar.styles'

type Props = {
  children: ReactElement
  className?: string
}

export const AvatarSubIcon = ({ children, className }: Props) => (
  <div className={className} css={subIcon} data-testid="avatar-sub-icon">
    {children}
  </div>
)
