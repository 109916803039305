import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { appBar, size } from '@twisto/styles'

export const root = (theme: Theme) => css`
  width: 100%;
  height: ${appBar.minHeight};
  border-bottom: 1px solid ${theme.palette.neutral[200]};
  overflow: hidden;
`

export const sectionName = css`
  padding: 0 ${size[24]};
`

export const sectionNameWithStartAdornment = css`
  padding: 0 ${size[16]};
`

export const sectionNameContainer = css`
  width: auto;
`

export const endAdornment = css`
  width: auto;
`
export const startAdornment = css`
  width: auto;
  margin-left: ${size[24]};
`

export const endAdornmentOffset = css`
  padding-right: ${size[24]};
`
