import type { Theme } from '@emotion/react'
import { css, keyframes } from '@emotion/react'

import {
  borderRadius,
  breakpoint,
  duration,
  easing,
  size,
} from '@twisto/styles'

const loadingAnimation = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`

export const root = (theme: Theme) => css`
  width: 100%;

  background-color: ${theme.palette.neutral[100]};
  animation: ${loadingAnimation} ${duration.longest}ms infinite
    ${easing.easeInOut};
  border-radius: ${borderRadius.sm};

  ${breakpoint.up('sm')} {
    border-radius: ${borderRadius.lg};
  }
`

export const variant = {
  default: css`
    &:after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  `,
  circle: css`
    border-radius: ${borderRadius.circle};

    ${breakpoint.up('sm')} {
      border-radius: ${borderRadius.circle};
    }
    &:after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  `,
  rectangle: css`
    &:after {
      content: '';
      padding-top: 56.25%;
      display: block;
    }
  `,
  row: css`
    height: ${size[16]};
    margin: ${size[8]} ${size[0]};
    border-radius: ${borderRadius.xs};

    &:nth-of-type(2) {
      max-width: 60%;
    }

    &:nth-of-type(3) {
      max-width: 80%;
    }

    ${breakpoint.up('sm')} {
      height: ${size[24]};
      border-radius: ${borderRadius.sm};
    }
  `,
}
