import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import {
  appBar,
  borderRadius,
  breakpoint,
  duration,
  easing,
  size,
} from '@twisto/styles'

export const root = (theme: Theme) => css`
  height: ${appBar.minHeight};
  cursor: pointer;
  transition: background ${duration.short}ms ${easing.easeIn};
  color: ${theme.palette.neutral[700]};

  &:hover,
  &:focus {
    background: ${theme.palette.neutral[200]};
  }
`

export const spacing = css`
  padding: ${size[12]} ${size[24]} ${size[12]};
  padding-left: ${size[16]};
`

export const skeletonWrapper = css`
  display: flex;
  width: 240px;
  padding: ${size[12]} ${size[24]} ${size[12]};
  padding-left: ${size[16]};
`

export const avatarSkeleton = css`
  flex: 0 1 ${size[40]};
  border-radius: ${borderRadius.circle};
  margin-right: ${size[12]};

  ${breakpoint.up('sm')} {
    border-radius: ${borderRadius.circle};
  }
`

export const avatar = css`
  margin-right: ${size[12]};
`

export const label = css`
  margin-right: ${size[16]};
`

export const dropdownMenu = css`
  width: 320px;
  max-width: 100%;
`

export const linkSection = css`
  padding: ${size[12]} 0;
`
