import type { ReactElement, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { useInView } from 'react-intersection-observer'

import { styleUtils } from '@twisto/styles'

type Props = {
  hasMore: boolean
  children: ReactNode
  loader: ReactElement
  dataLength: number
  loadMore: (page: number) => void
}

export const InfiniteScroll = ({
  hasMore,
  loader,
  loadMore,
  dataLength,
  children,
}: Props) => {
  const [, setPage] = useState(0)
  const [loaderRef, inView] = useInView({
    rootMargin: '25%',
  })

  useEffect(() => {
    if (inView && hasMore) {
      setPage((page) => {
        loadMore(page)

        return page + 1
      })
    }
  }, [inView, hasMore, dataLength, loadMore])

  return (
    <>
      {children}
      {hasMore && (
        <div ref={loaderRef} css={styleUtils.width.full}>
          {loader}
        </div>
      )}
    </>
  )
}
