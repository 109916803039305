import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, breakpoint, size, width } from '@twisto/styles'

export const section = css`
  ${breakpoint.up('sm')} {
    padding-left: ${width.navigationSidebar.collapsed};
  }

  ${breakpoint.up('lg')} {
    padding-left: ${width.navigationSidebar.full};
  }
`

export const contentWrapper = (theme: Theme) => css`
  ${breakpoint.down('xs')} {
    border-radius: ${borderRadius.xl} ${borderRadius.xl} 0 0;
    background: ${theme.palette.background.secondary};
    overflow: hidden;
    min-height: calc(
      100vh - (${size[72]} * 2)
    ); // height of full screen without size of app bar and navigation

    @supports (min-height: 100dvh) {
      min-height: calc(
        100dvh - (${size[72]} * 2)
      ); // height of avaible screen without size of app bar and navigation
    }
  }
`

export const sectionContent = (theme: Theme) => css`
  ${breakpoint.up('sm')} {
    margin: ${size[16]};
    overflow: hidden;
    border-radius: ${borderRadius.lg};
    background: ${theme.palette.background.secondary};
  }

  ${breakpoint.up('lg')} {
    margin-left: ${size[0]};
  }
`
