import type { ReactElement, ReactNode } from 'react'

import { Flex } from '@twisto/components/atoms/flex'
import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './desktop-app-bar.styles'

type Props = {
  label: ReactNode
  startAdornment?: ReactElement
  endAdornment: ReactElement
  endAdornmentOffset?: boolean
}

export const DesktopAppBar = ({
  label,
  endAdornment,
  startAdornment,
  endAdornmentOffset = false,
}: Props) => (
  <Flex
    alignItems="center"
    css={styles.root}
    data-testid="desktop-app-bar"
    justify="spaceBetween"
    wrap="noWrap"
  >
    <Flex alignItems="center" css={styles.sectionNameContainer} wrap="noWrap">
      {startAdornment && (
        <Flex alignItems="center" css={styles.startAdornment} wrap="noWrap">
          {startAdornment}
        </Flex>
      )}
      <Typography
        css={[
          styles.sectionName,
          startAdornment && styles.sectionNameWithStartAdornment,
        ]}
        variant="h3"
      >
        {label}
      </Typography>
    </Flex>
    <Flex
      alignItems="center"
      css={[
        styles.endAdornment,
        endAdornmentOffset && styles.endAdornmentOffset,
      ]}
      wrap="noWrap"
    >
      {endAdornment}
    </Flex>
  </Flex>
)
