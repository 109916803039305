import { useState } from 'react'

import { isNil } from 'ramda'

import { useNavigationBannersQuery } from '@twisto/api-graphql'
import { Skeleton } from '@twisto/components/atoms/skeleton'
import { NavigationSideBar } from '@twisto/components/molecules/navigation-sidebar'
import { useBreakpoint } from '@twisto/hooks'

import { useCustomerConfig } from 'customer/components/customer-config'

import {
  DesktopMainNavigationList,
  ReferralBox,
  VerificationBox,
} from './components'

export const MainNavigation = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { featureFlags } = useCustomerConfig()
  const isSmallDevice = useBreakpoint('mdDown')
  const { data, loading } = useNavigationBannersQuery()

  const getEndAdornment = () => {
    // if data is not loaded yet, show skeleton
    if (loading) {
      return <Skeleton />
    }

    // check and display `onboardingPlanTrial` which links to verification app
    if (!isNil(data) && !isNil(data.banners.onboardingPlanTrial)) {
      return (
        <VerificationBox
          linkUrl={data.banners.onboardingPlanTrial.link}
          trialDays={data.banners.onboardingPlanTrial.trialDays}
        />
      )
    }

    // otherwise if referral feature is available (premium users), show referral box
    if (featureFlags.referral === true) {
      return <ReferralBox />
    }
  }

  return (
    <NavigationSideBar
      endAdornment={getEndAdornment()}
      setIsCollapsed={setIsCollapsed}
      variant={isSmallDevice ? 'collapsed' : 'full'}
    >
      <DesktopMainNavigationList isCollapsed={isCollapsed} />
    </NavigationSideBar>
  )
}
