import { InView } from 'react-intersection-observer'

import type { NotificationDataFragment } from '@twisto/api-graphql'

import { Notification } from '../notification'

import * as styles from './notification-list.styles'

export const NotificationInViewWrapper = ({
  withInViewListener = false,
  notification,
  onSeen,
}: {
  withInViewListener?: boolean
  notification: NotificationDataFragment
  onSeen: (notification: NotificationDataFragment) => void
}) => (
  <div css={styles.notification} data-testid="notifications-dropdown-record">
    {withInViewListener ? (
      <InView
        as="div"
        initialInView={false}
        threshold={1}
        triggerOnce={true}
        onChange={(inView) => inView && onSeen(notification)}
      >
        <Notification data={notification} />
      </InView>
    ) : (
      <Notification data={notification} />
    )}
  </div>
)
