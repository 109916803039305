import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const dropdownContent = (theme: Theme) => css`
  padding: ${styles.size[24]};
  width: 480px;
  max-width: 100%;
  max-height: 800px;
  overflow: auto;
  background: ${theme.palette.background.primary};
`

const gradientCommon = css`
  position: absolute;
  left: 0;
  width: 100%;
  height: 64px;
  opacity: 0;
  transition: opacity ${styles.easing.easeInOut} ${styles.duration.gentle}ms;
  pointer-events: none;
`

export const topGradient = (theme: Theme) => css`
  ${gradientCommon}
  top: 0;
  /* TODO: create helper for gradients and use it here */
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    ${theme.palette.background.primary} 100%
  );
`

export const bottomGradient = (theme: Theme) => css`
  ${gradientCommon}
  bottom: 0;
  /* TODO: create helper for gradients and use it here */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    ${theme.palette.background.primary} 100%
  );
`
export const gradientVisible = css`
  opacity: 1;
`

export const avatarRootOverrides = css`
  margin: 0 ${styles.size[8]};
`

export const badge = css`
  width: auto;
  height: auto;
  top: -${styles.size[4]};
  bottom: initial;
  right: -${styles.size[4]};

  ${styles.breakpoint.up('sm')} {
    top: -calc(${styles.size[4]} / 2);
    right: -calc(${styles.size[4]} / 2);
  }
`

export const notificationsIcon = (theme: Theme) => css`
  background: ${theme.palette.neutral[200]};
`

export const notificationsIconActive = (theme: Theme) => css`
  color: ${theme.palette.additionalColor.purple[500]};
`
