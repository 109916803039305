import type { IconComponent } from '@twisto/icons'

import * as styles from './icon-circle.styles'

export type IconCircleColors =
  | 'primary'
  | 'success'
  | 'error'
  | 'warning'
  | 'gray'
  | 'actionPrimary'
export type IconCircleSizes = '24' | '32' | '40' | '48' | '64' | '80'
type IconSizes = '16' | '20' | '24' | '32' | '40' | '48'

type Props = {
  color?: IconCircleColors
  icon: IconComponent
  size?: IconCircleSizes
  className?: string
  titleAccess?: string
}

const iconSizeMap: Record<IconCircleSizes, IconSizes> = {
  '24': '16',
  '32': '20',
  '40': '24',
  '48': '32',
  '64': '40',
  '80': '48',
}

export const IconCircle = ({
  icon,
  color = 'gray',
  size = '40',
  className,
  titleAccess,
}: Props) => {
  const Icon = icon
  const iconSize = iconSizeMap[size]
  const iconColor = color === 'actionPrimary' ? 'white' : color

  return (
    <div
      className={className}
      css={(theme) => styles.root(theme, color, size)}
      data-testid="icon-circle"
    >
      <Icon color={iconColor} size={iconSize} titleAccess={titleAccess} />
    </div>
  )
}
