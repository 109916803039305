import { Trans } from '@lingui/react'

import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { VerticalBanner } from '@twisto/components/molecules/vertical-banner'
import { GiftOutlineIcon } from '@twisto/icons'

import { appRoutes } from 'customer/routes'

export const ReferralBox = () => (
  <VerticalBanner
    icon={<IconCircle color="primary" icon={GiftOutlineIcon} size="48" />}
    linkLabel={<Trans id="components.molecules.referralBox.button" />}
    linkProps={{ href: appRoutes.referral.path }}
    subtitle={<Trans id="components.molecules.referralBox.subtitle" />}
    title={<Trans id="components.molecules.referralBox.title" />}
  />
)
