import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Logo } from '@twisto/components/atoms/logo'
import { duration, styleUtils } from '@twisto/styles'

import * as styles from './navigation-sidebar.styles'

type Props = {
  variant: keyof typeof styles.widths
  children: ReactNode
  endAdornment?: ReactNode
  setIsCollapsed?: (collapsed: boolean) => void
}

export const NavigationSideBar = ({
  children,
  endAdornment,
  variant: requestedVariant = 'full',
  setIsCollapsed,
}: Props) => {
  const timeoutId = useRef<NodeJS.Timeout>()
  const [isInFull, setIsInFull] = useState(requestedVariant === 'full')
  const [variant, setVariant] = useState(requestedVariant)

  useEffect(() => {
    setVariant(requestedVariant)
  }, [requestedVariant])

  useEffect(() => {
    if (setIsCollapsed) {
      setIsCollapsed(variant === 'collapsed')
    }
  }, [variant, setIsCollapsed])

  const handleOnMouseOver = () => {
    if (variant === 'collapsed') {
      setVariant('full')
    }
  }

  const handleOnMouseLeave = () => {
    if (variant !== requestedVariant) {
      setVariant(requestedVariant)
    }
  }

  useEffect(() => {
    if (variant === 'full') {
      timeoutId.current = setTimeout(() => {
        setIsInFull(true)
      }, duration.short)
    } else {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      setIsInFull(false)
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [variant])

  return (
    <div css={[styles.widths[requestedVariant], styleUtils.height.full]}>
      <nav
        css={(theme) => [
          styles.navigation(theme),
          styles.widths[variant],
          requestedVariant === 'collapsed' && styles.collapsedNavigation,
        ]}
        data-testid="navigation-sidebar"
        onMouseLeave={handleOnMouseLeave}
        onMouseOver={handleOnMouseOver}
      >
        <header
          css={[
            styles.header,
            requestedVariant === 'collapsed' && styles.collapsedHeader,
          ]}
        >
          <Logo variant={requestedVariant === 'full' ? 'full' : 'icon'} />
        </header>
        <div>{children}</div>
        {variant === 'full' && (
          <footer css={[styles.footer, !isInFull && styles.hidden]}>
            {endAdornment}
          </footer>
        )}
      </nav>
    </div>
  )
}
