import { Trans } from '@lingui/react'

import { useAppConfig } from '@twisto/base/app-config'
import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { VerticalBanner } from '@twisto/components/molecules/vertical-banner'
import { GiftOutlineIcon } from '@twisto/icons'
import { daysToNumberOfMonths } from '@twisto/locale'

type Props = {
  linkUrl: string
  trialDays: number
}
export const VerificationBox = ({ linkUrl, trialDays }: Props) => {
  const { region } = useAppConfig()

  const trialPeriod = daysToNumberOfMonths(trialDays)

  const getSubtitle = () => {
    switch (region) {
      case 'PL':
        return (
          <Trans
            id="customer.navigation.verificationBox.subtitle.space"
            values={{ trialPeriod }}
          />
        )
      default:
        return (
          <Trans
            id="customer.navigation.verificationBox.subtitle.premiumPaid"
            values={{ trialPeriod }}
          />
        )
    }
  }

  const getTitle = () => {
    switch (region) {
      case 'PL':
        return <Trans id="customer.navigation.verificationBox.title" />
      default:
        return (
          <Trans id="customer.navigation.verificationBox.paidPremiumTitle" />
        )
    }
  }

  return (
    <VerticalBanner
      icon={<IconCircle color="primary" icon={GiftOutlineIcon} size="48" />}
      linkLabel={<Trans id="customer.navigation.verificationBox.linkLabel" />}
      linkProps={{ href: linkUrl }}
      subtitle={getSubtitle()}
      title={getTitle()}
    />
  )
}
