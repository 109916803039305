import type { ReactElement } from 'react'

import { useRouter } from 'next/router'
import { isNil } from 'ramda'

import { Link } from '@twisto/components/atoms/link'
import { Typography } from '@twisto/components/atoms/typography'
import { useMatch } from '@twisto/hooks'
import type { IconComponent } from '@twisto/icons'

import * as styles from './mobile-navigation.styles'

type Props = {
  label: ReactElement
  to?: string
  href?: string
  icon: IconComponent
  onClick?: () => void
  activeIcon?: IconComponent
  active?: boolean
}

export const MobileNavigationLink = ({
  icon,
  label,
  to,
  href,
  active,
  activeIcon,
  onClick,
}: Props) => {
  // TODO: remove once merchant portal wont use / route: WAP-2111
  const { asPath } = useRouter()
  const path = href ?? to ?? ''
  const match = useMatch(`${path}*`)
  const isActive =
    path === '/'
      ? path === asPath
      : (!isNil(match) && active !== false) || active === true
  const Icon = isActive ? activeIcon ?? icon : icon

  return (
    <Link
      aria-current={isActive ? 'page' : undefined}
      css={(theme) => [
        styles.mobileNavigationLinkRoot(theme),
        isActive && styles.activeLink(theme),
      ]}
      data-testid="mobile-navigation-link"
      href={href}
      to={to}
      onClick={onClick}
    >
      <Icon
        color={isActive ? 'primary' : 'inherit'}
        css={styles.linkIcon}
        size="24"
      />
      <Typography
        color={isActive ? 'primary' : undefined}
        css={styles.navigationLinkLabel}
        fontWeight="medium"
        variant="b2"
      >
        {label}
      </Typography>
    </Link>
  )
}
