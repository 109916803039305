import { Trans } from '@lingui/react'

import { Divider } from '@twisto/components/atoms/divider'
import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './desktop-main-navigation-list.styles'

type Props = {
  isCollapsed: boolean
}

export const DesktopNavigationFeaturesDivider = ({ isCollapsed }: Props) => {
  if (isCollapsed) {
    return <Divider css={styles.divider} />
  }

  return (
    <Typography
      css={styles.lockedVisibleSectionsLabel}
      fontWeight="medium"
      variant="b3"
    >
      <Trans id="customer.sideNavigation.fullAccountFeatures" />
    </Typography>
  )
}
