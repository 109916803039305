import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = (theme: Theme) => css`
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.palette.neutral[700]};
  text-align: left;
  padding: 0;

  &:hover,
  &:focus {
    color: ${theme.palette.neutral[900]};
  }
`

export const dropdownMenu = css`
  position: absolute;
  z-index: ${styles.zIndex.modal};
  user-select: none;
  max-width: calc(100% - 32px);
  min-width: 16px;
  width: fit-content;
  overflow: hidden;

  transition:
    ${styles.duration.shortest}ms ${styles.easing.easeInOut} opacity,
    ${styles.duration.shortest}ms ${styles.easing.easeInOut} transform;

  opacity: 0;
  transform: scale(0.9);
`

export const dropdownTriangle = (theme: Theme, position: number) => css`
  &:after {
    position: absolute;
    content: '';
    display: block;
    top: -6px;
    left: ${position}px;

    width: 12px;
    height: 12px;
    border-radius: 2px 0 0 0;
    transform: rotate(45deg);
    background: ${theme.palette.background.primary};

    z-index: -1;
  }
`

export const dropdownMenuFadeIn = css`
  opacity: 1;
  transform: scale(1);
`

export const childrenWrapper = (theme: Theme) => css`
  background: ${theme.palette.background.primary};
  border-radius: ${styles.borderRadius.md};
  overflow: hidden;
`
