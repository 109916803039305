import type { ReactNode } from 'react'

import NextImage from 'next/image'
import { isNil } from 'ramda'

import { Flex } from '@twisto/components/atoms/flex'
import { Typography } from '@twisto/components/atoms/typography'
import { UserFilledIcon } from '@twisto/icons'

import * as styles from './user-avatar.styles'

const getUserInitials = (name: string) => {
  const nameParts = name.split(' ')
  const firstInitial = nameParts[0].charAt(0)
  const lastInitial = nameParts[nameParts.length - 1].charAt(0)

  return `${firstInitial}${lastInitial}`
}

type Props = {
  src?: string
  alt?: string
  size: keyof typeof styles.avatarSize
  name?: string
  children?: ReactNode
  className?: string
}

export const UserAvatar = ({
  name,
  alt,
  src,
  size,
  children,
  className,
}: Props) => {
  const initials = !isNil(name) ? getUserInitials(name) : undefined

  const getAvatarContent = () => {
    // if customer has image as avatar
    if (!isNil(src)) {
      return (
        <NextImage
          alt={!isNil(alt) ? alt : name ?? ''}
          css={styles.image}
          height="96"
          src={src}
          width="96"
        />
      )
    }

    // if customer has name
    if (!isNil(name)) {
      return (
        <Typography
          color="secondary"
          css={styles.initials[size]}
          fontWeight="medium"
        >
          {initials}
        </Typography>
      )
    }

    // if customer does not have img or name initials
    // display default avatar icon
    return <UserFilledIcon css={styles.defaultIcon} />
  }

  return (
    <div css={styles.container} data-testid="user-avatar">
      <Flex
        alignItems="center"
        className={className}
        css={(theme) => [styles.avatar(theme), styles.avatarSize[size]]}
        justify="center"
        title={name}
      >
        {getAvatarContent()}
      </Flex>
      {children}
    </div>
  )
}
