import type { ReactNode } from 'react'

import * as styles from './action-list.styles'

type Props = {
  children?: ReactNode[]
  className?: string
}

export const ActionList = ({ children, className, ...rest }: Props) => (
  <div
    className={className}
    css={styles.container}
    data-testid="action-list"
    {...rest}
  >
    {children}
  </div>
)
