import type { ReactElement } from 'react'
import { cloneElement } from 'react'

import { isNil } from 'ramda'

import { Avatar } from '@twisto/components/atoms/avatar'
import { Typography } from '@twisto/components/atoms/typography'
import { useBreakpoint } from '@twisto/hooks'
import type { IconProps } from '@twisto/icons'
import { styleUtils } from '@twisto/styles'

import * as styles from './action-content.styles'

type Props = {
  disabled?: boolean
  // icon size is controlled by Action fill the size prop only in case you wish to override the default responsive behavior
  icon: ReactElement
  endAdornment?: ReactElement
  caption: ReactElement | string
  label?: ReactElement | string
}

export const ActionContent = ({
  disabled = false,
  icon,
  caption,
  label,
  endAdornment,
}: Props) => {
  const isMobileDevice = useBreakpoint('xsDown')
  const mobileIconSize = isMobileDevice ? '32' : '40'

  const sizeProp = icon.props.size as IconProps['size']
  const iconSize =
    isNil(sizeProp) || sizeProp === 'inherit' ? mobileIconSize : sizeProp

  const Icon = cloneElement<Pick<IconProps, 'size' | 'color'>>(icon, {
    // if icon size is specified the size will be used otherwise the default responsive value is used
    size: iconSize,
    color: disabled ? 'gray' : icon.props.color,
  })

  return (
    <>
      <Avatar
        noPlaceholder
        size={isMobileDevice ? '32' : '40'}
        styles={{ root: styles.avatarIcon, children: styles.icon }}
      >
        {Icon}
      </Avatar>
      <div css={styleUtils.flex.grow} data-testid="action-content">
        <Typography
          align="start"
          color="textPrimary"
          css={disabled && styles.disabled}
          variant="b2"
        >
          {caption}
        </Typography>
        {label !== undefined && (
          <Typography
            align="start"
            color="textSecondary"
            css={disabled && styles.disabled}
            variant="b4"
          >
            {label}
          </Typography>
        )}
      </div>
      {endAdornment && (
        <styles.styledEndAdornment>{endAdornment}</styles.styledEndAdornment>
      )}
    </>
  )
}
