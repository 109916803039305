import type { ReactElement } from 'react'

import type { IconCircleColors } from '@twisto/components/atoms/icon-circle'
import { IconCircle } from '@twisto/components/atoms/icon-circle'
import { Action } from '@twisto/components/molecules/action'
import type { IconComponent } from '@twisto/icons'

import * as styles from './mobile-navigation.styles'

type Props = {
  label: ReactElement
  to?: string
  href?: string
  onClick?: () => void
  icon: IconComponent
  iconColor?: IconCircleColors
  subLabel?: ReactElement | string
}

export const MobileNavigationMoreLink = ({
  icon: Icon,
  label,
  subLabel,
  to,
  href,
  iconColor,
  onClick,
}: Props) => (
  <Action
    caption={label}
    css={styles.moreLink}
    data-testid="mobile-navigation-more-link"
    href={href}
    icon={<IconCircle color={iconColor ?? 'primary'} icon={Icon} size="32" />}
    label={subLabel}
    to={to}
    onClick={onClick}
  />
)
