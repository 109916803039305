import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const container = css`
  position: relative;
`

export const avatar = (theme: Theme) => css`
  position: relative;
  border-radius: ${styles.borderRadius.circle};
  background-color: ${theme.palette.additionalColor.purple[100]};
  overflow: hidden;
  cursor: default;
  user-select: none;
`

export const avatarSize = {
  sm: css`
    width: ${styles.size[40]};
    height: ${styles.size[40]};
  `,
  md: css`
    width: ${styles.size[64]};
    height: ${styles.size[64]};
  `,
  lg: css`
    width: ${styles.size[96]};
    height: ${styles.size[96]};
  `,
}

export const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const initials = {
  sm: css`
    font-size: ${styles.fontSize.xxs};
  `,
  md: css`
    font-size: ${styles.fontSize.sm};
  `,
  lg: css`
    font-size: ${styles.fontSize.md};
  `,
}

export const defaultIcon = (theme: Theme) => css`
  width: 75%;
  height: 75%;
  color: ${theme.palette.additionalColor.purple[200]};
`
