import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { size } from '@twisto/styles'

export const moreMenuDropDownRoot = css`
  width: 100%;
`

export const moreMenu = css`
  max-width: 320px;
  width: 320px;
  padding: ${size[16]} 0;
`

export const lockedVisibleSectionsLabel = (theme: Theme) => css`
  color: ${theme.palette.neutral[600]};
  opacity: 0.6;
  margin: ${size[8]} ${size[24]};
  white-space: nowrap;
  text-transform: uppercase;
`

export const divider = css`
  margin: 19px ${size[24]};
  max-width: ${size[24]};
`
