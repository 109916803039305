import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, size } from '@twisto/styles'
import { getGradient } from '@twisto/theme'

export const root = css`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${borderRadius.sm};
  padding: ${size[8]} ${size[16]};
  font-weight: inherit;
  width: 100%;
  border: none;
  background: transparent;
`

export const clickable = (theme: Theme) => css`
  cursor: pointer;

  &:hover {
    background: ${getGradient(theme, 'linearGray')};
  }

  &:focus,
  &:active,
  &:focus-within {
    background: ${getGradient(theme, 'linearGray', 200)};
  }
`

export const noPadding = css`
  padding: 0;
`
