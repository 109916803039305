import { css } from '@emotion/react'

import { duration, size } from '@twisto/styles'

export const root = css`
  height: 56px;
  width: 224px;
  margin: ${size[4]} 0;
  padding-left: ${size[24]};
  position: relative;
  white-space: nowrap;
  flex-wrap: nowrap;
  transition: width ${duration.complex}ms;
`

export const label = css`
  margin: 0 ${size[16]};
  transition: all ${duration.complex}ms;
`
export const labelHidden = css`
  transform: translate(-20px, 0) scale(0.8);
  opacity: 0;
`
