import type { ComponentProps, ReactNode } from 'react'

import { isNil } from 'ramda'

import { BackButton } from '@twisto/components/atoms/back-button'
import { DesktopAppBar } from '@twisto/components/molecules/desktop-app-bar'

import { NotificationsDropdown } from 'customer/components/notifications'

import { UserAccountButton } from '../user-account-button'

import * as styles from './customer-desktop-app-bar.styles'

type Props = {
  sectionName: ReactNode
  backButtonProps?: ComponentProps<typeof BackButton>
}
export const CustomerDesktopAppBar = ({
  sectionName,
  backButtonProps,
}: Props) => (
  <DesktopAppBar
    endAdornment={
      <>
        <div css={styles.notifications}>
          <NotificationsDropdown />
        </div>
        <UserAccountButton />
      </>
    }
    label={sectionName}
    startAdornment={
      !isNil(backButtonProps) ? <BackButton {...backButtonProps} /> : undefined
    }
  />
)
