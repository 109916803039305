import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, size } from '@twisto/styles'

import type { IconCircleColors, IconCircleSizes } from './icon-circle'

const colors = (theme: Theme, color: IconCircleColors) =>
  ({
    primary: css`
      background-color: ${theme.palette.brand[100]};
    `,
    success: css`
      background-color: ${theme.palette.success[100]};
    `,
    warning: css`
      background-color: ${theme.palette.warning[100]};
    `,
    error: css`
      background-color: ${theme.palette.error[100]};
    `,
    gray: css`
      background-color: ${theme.palette.neutral[100]};
    `,
    actionPrimary: css`
      background-color: ${theme.palette.action[500]};
    `,
  })[color]

export const root = (
  theme: Theme,
  color: IconCircleColors,
  iconSize: IconCircleSizes
) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${size[iconSize]};
  width: ${size[iconSize]};
  border-radius: ${borderRadius.circle};

  ${colors(theme, color)};
`
