import type { ComponentProps, ReactNode } from 'react'

import { isNil } from 'ramda'

import { BackButton } from '@twisto/components/atoms/back-button'
import { Flex } from '@twisto/components/atoms/flex'
import { Logo } from '@twisto/components/atoms/logo'
import { ModalCloser } from '@twisto/components/atoms/modal'
import { Typography } from '@twisto/components/atoms/typography'
import { useNavigate } from '@twisto/hooks'

import { NotificationsDropdown } from 'customer/components/notifications'

import * as styles from './customer-mobile-app-bar.styles'

type Props = {
  sectionName: ReactNode
  backButtonProps?: ComponentProps<typeof BackButton>
  border?: boolean
  noBackground?: boolean
  closePath?: string | null
}

export const CustomerMobileAppBar = ({
  sectionName,
  border = false,
  noBackground = false,
  backButtonProps,
  closePath,
}: Props) => {
  const navigate = useNavigate()

  const handleClose = () => {
    if (!isNil(closePath)) {
      navigate(closePath)
    }
  }

  return (
    <div
      css={(theme) => [
        styles.root(theme, noBackground),
        border && styles.border(theme),
      ]}
    >
      <Flex justify="flexStart">
        {!isNil(backButtonProps) ? (
          <BackButton {...backButtonProps} />
        ) : (
          <Logo size="large" />
        )}
      </Flex>
      <Typography align="center" fontWeight="medium" variant="b1">
        {sectionName}
      </Typography>
      {isNil(closePath) ? (
        <Flex justify="flexEnd">
          <NotificationsDropdown />
        </Flex>
      ) : (
        <ModalCloser css={styles.closeButton} onClick={handleClose} />
      )}
    </div>
  )
}
