import type { Theme } from '@emotion/react'

import type { NeutralPaletteColor } from './themes'

type GradientVariants = 'linearGray'

export const getGradient = (
  theme: Theme,
  gradient: GradientVariants,
  variant: keyof NeutralPaletteColor = 100
) => {
  const gradients = {
    linearGray: {
      [variant]: `linear-gradient(90deg, transparent 0%, ${theme.palette.neutral[variant]} 100%)`,
    },
  }

  return gradients[gradient][variant]
}
