import { clamp, isNil } from 'ramda'

import { Typography } from '../typography'

import * as styles from './notification.styles'

const MIN_VALUE = 0
const MAX_VALUE = 99

type Value = string | number | null

const getFormattedValue = (value?: Value) => {
  if (isNil(value)) {
    return null
  }

  const number = Number(value)

  if (Number.isNaN(number)) {
    return null
  }

  return clamp(MIN_VALUE, MAX_VALUE, number)
}

type Props = {
  value?: Value
}

export const Notification = ({ value }: Props) => {
  const formattedValue = getFormattedValue(value)
  const hasValue = !isNil(formattedValue)

  return (
    <div
      css={[styles.root, !hasValue && styles.withoutNumber]}
      data-testid="notification"
    >
      {hasValue ? (
        <Typography
          align="center"
          component="span"
          css={styles.value}
          fontWeight="medium"
          variant="b4"
        >
          {formattedValue}
        </Typography>
      ) : null}
    </div>
  )
}
