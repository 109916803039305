import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const container = css`
  display: grid;
  width: 100%;
  grid-gap: ${styles.size[8]};

  ${styles.breakpoint.up('md')} {
    grid-gap: ${styles.size[16]};
  }
`
