import type { ReactElement } from 'react'

import type { Theme } from '@emotion/react'
import NextImage from 'next/image'
import { isNil } from 'ramda'

import { AvatarSubIcon } from '@twisto/components/atoms/avatar/avatar-sub-icon'
import type { CssClasses } from '@twisto/styles'
import type { RequireOnlyOne } from '@twisto/utils'

import * as styles from './avatar.styles'

type CssClassesApi = Pick<typeof styles, 'root' | 'children' | 'subIcon'>

export type AvatarProps = RequireOnlyOne<
  CssClasses<CssClassesApi> & {
    image: ImageMeta
    src: string
    alt?: string
    subIcon?: ReactElement
    children?: ReactElement
    size?: styles.AvatarSizes
    noPlaceholder?: boolean
    noBorder?: boolean
  },
  'image' | 'src' | 'children'
>

export const Avatar = ({
  image,
  alt,
  src,
  subIcon,
  children,
  styles: stylesProp = {},
  className,
  size = '40',
  noPlaceholder,
  noBorder = false,
  ...other
}: AvatarProps) => {
  const rootStyles = (theme: Theme) => [
    styles.root(theme),
    styles.size(size),
    !!subIcon && styles.spacingRight,
    (noPlaceholder ?? false) && styles.noPlaceholderBackground,
    stylesProp.root,
  ]

  const imageStyles = (theme: Theme) => [
    styles.mainIconStyle(theme, noBorder),
    styles.image,
  ]

  const chidlrenWrapperStyles = (theme: Theme) => [
    styles.mainIconStyle(theme, noBorder),
    styles.children,
    stylesProp.children,
  ]

  return (
    <div className={className} css={rootStyles} data-testid="avatar">
      {src !== undefined && alt !== undefined && (
        <NextImage
          alt={alt}
          css={imageStyles}
          height={size}
          src={src}
          width={size}
          {...other}
        />
      )}
      {image !== undefined && !isNil(alt) && (
        <NextImage alt={alt} height={size} src={image} width={size} />
      )}
      {subIcon && (
        <AvatarSubIcon css={stylesProp.subIcon}>{subIcon}</AvatarSubIcon>
      )}
      {children && <div css={chidlrenWrapperStyles}>{children}</div>}
    </div>
  )
}
