import * as styles from './skeleton.styles'

type Props = {
  variant?: keyof typeof styles.variant
  className?: string
}

export const Skeleton = ({ variant = 'default', ...other }: Props) => (
  <div
    css={[styles.root, styles.variant[variant]]}
    data-testid="skeleton"
    {...other}
  />
)
