import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

// Tune this to set how dense to show icons in the row
export const WIDTH_PER_LINK = 65

const HEIGHT = styles.size[72]
const TOP_CURVE_RADIUS = styles.borderRadius.xl

export const root = (theme: Theme) => css`
  height: ${HEIGHT};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.palette.background.primary};
  box-shadow: 0 0 ${TOP_CURVE_RADIUS} 0 ${theme.palette.neutral[300]};
  z-index: ${styles.zIndex.drawer};

  &::before,
  &::after {
    content: '';
    position: absolute;

    background-color: transparent;
    top: calc(${TOP_CURVE_RADIUS} * -2);
    height: calc(${TOP_CURVE_RADIUS} * 2);
    width: ${TOP_CURVE_RADIUS};
    box-shadow: 0 ${TOP_CURVE_RADIUS} 0 0 ${theme.palette.background.primary};
    z-index: -1;
  }

  &::before {
    border-bottom-left-radius: ${TOP_CURVE_RADIUS};
  }

  &::after {
    right: 0;
    border-bottom-right-radius: ${TOP_CURVE_RADIUS};
  }
`

export const placeholder = css`
  position: relative;
  height: ${HEIGHT};
  width: 100%;
  padding-top: ${TOP_CURVE_RADIUS};
`

export const buttonRowContainer = css`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const mobileNavigationLinkRoot = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${WIDTH_PER_LINK}px;
  cursor: pointer;
  color: ${theme.palette.neutral[600]};
`

export const activeLink = (theme: Theme) => css`
  color: ${theme.palette.brand[500]};
`

export const linkIcon = css`
  margin-bottom: ${styles.size[4]};
`

export const moreLink = css`
  padding: ${styles.size[8]} ${styles.size[24]};
`

export const navigationLinkLabel = css`
  text-align: center;
  color: inherit;

  ${styles.breakpoint.down('sm')} {
    font-size: 11px;
  }
`

export const moreLinksOverlay = (theme: Theme) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-bottom: ${HEIGHT};
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.primary};
  z-index: ${styles.zIndex.drawer};

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`

export const moreLinksWrapper = (theme: Theme) => css`
  height: 100%;
  overflow: scroll;
  border-radius: ${styles.borderRadius.xl} ${styles.borderRadius.xl} 0 0;
  background-color: ${theme.palette.background.secondary};
  padding: ${styles.size[20]} 0;
`

export const group = css`
  margin-bottom: ${styles.size[16]};
`
export const groupLabel = css`
  margin: ${styles.size[4]} ${styles.size[24]} ${styles.size[8]};
`
