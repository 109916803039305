import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { duration, easing, size, width, zIndex } from '@twisto/styles'

export const navigation = (theme: Theme) => css`
  display: grid;
  grid-template-rows: max-content auto max-content;
  transition: ${duration.short}ms ${easing.easeInOut};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: ${theme.palette.background.primary};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: ${zIndex.drawer};
`

export const collapsedNavigation = css`
  /* TODO: refactor shadows based on figma Elevations */
  box-shadow:
    0px 2px 16px rgba(42, 39, 46, 0.08),
    0px 2px 4px rgba(42, 39, 46, 0.04);
`

export const widths = {
  full: css`
    width: ${width.navigationSidebar.full};
  `,
  collapsed: css`
    width: ${width.navigationSidebar.collapsed};
  `,
}

export const header = css`
  padding: ${size[32]} ${size[20]} ${size[40]};
`

export const collapsedHeader = css`
  ${widths.collapsed};
  display: flex;
  justify-content: center;
`

export const footer = css`
  padding: ${size[24]};
  opacity: 1;
  transition: opacity ${duration.short}ms ${easing.easeInOut};
`

export const hidden = css`
  opacity: 0;
`
