import type { ReactNode } from 'react'

import { Typography } from '../typography'

import * as styles from './dropdown-header.styles'

type Props = {
  label: ReactNode
}

export const DropDownHeader = ({ label }: Props) => (
  <div css={styles.root} data-testid="dropdown-header">
    <Typography variant="b1">{label}</Typography>
  </div>
)
