import type { ComponentProps, ReactElement } from 'react'

import { Box } from '@twisto/components/atoms/box'
import { Link } from '@twisto/components/atoms/link'
import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './vertical-banner.styles'

type Props = {
  icon: ReactElement
  title: ReactElement | string
  subtitle: ReactElement | string
  linkProps: Omit<ComponentProps<typeof Link>, 'children'>
  linkLabel: ReactElement | string
  className?: string
}

export const VerticalBanner = ({
  title,
  icon,
  subtitle,
  linkProps,
  linkLabel,
  className,
}: Props) => (
  <Box
    className={className}
    color="primary"
    css={styles.root}
    data-testid="vertical-banner"
  >
    <div css={styles.icon}>{icon}</div>
    <div css={styles.header}>
      <Typography color="textPrimary" fontWeight="medium" variant="b3">
        {title}
      </Typography>
      <Typography color="textSecondary" variant="b3">
        {subtitle}
      </Typography>
    </div>
    <Link {...linkProps}>
      <Typography fontWeight="medium" variant="b3">
        {linkLabel}
      </Typography>
    </Link>
  </Box>
)
