import { isNil } from 'ramda'

import { Link } from '@twisto/components/atoms/link'
import { useMatch } from '@twisto/hooks'
import type { IconComponent } from '@twisto/icons'

import { NavigationItemContent } from './navigation-item-content'
import * as styles from './navigation-item.styles'

type Props = {
  label: JSX.Element | string
  iconComponent: IconComponent
  activeIconComponent?: IconComponent
  to?: string
  href?: string
  target?: string
  onClick?: () => void
  collapsed: boolean
  mockMatch?: (pattern: string) => boolean
}

export const NavigationItem = ({
  label,
  to,
  collapsed,
  iconComponent,
  activeIconComponent,
  href,
  target,
  onClick,
}: Props) => {
  const match = useMatch(`${href ?? to}*`)
  const active = !isNil(match) && (!isNil(href) || !isNil(to))

  return (
    <Link
      aria-current={active ? 'page' : undefined}
      css={() => [styles.link, active && styles.linkActive]}
      data-testid="navigation-item"
      href={href}
      target={target}
      to={to}
      onClick={onClick}
    >
      <NavigationItemContent
        activeIconComponent={activeIconComponent}
        collapsed={collapsed}
        iconComponent={iconComponent}
        label={label}
      />
    </Link>
  )
}
