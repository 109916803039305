import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { useAtom } from 'jotai'
import { isNil } from 'ramda'

import { Hidden } from '@twisto/components/atoms/hidden'
import { useBreakpoint } from '@twisto/hooks'

import { AuthenticationGuard } from 'customer/components/authentication-guard'
import { CustomerApp } from 'customer/components/customer-app'
import type { Section } from 'customer/components/customer-app-page-title'
import { LiveChat, toggleLivechat } from 'customer/components/live-chat'

import { RemoteSectionsAvailabilityLoader } from '../customer-config'
import {
  CustomerDesktopAppBar,
  CustomerMobileAppBar,
  CustomerMobileNavigation,
} from '../navigation/components'
import { MainNavigation } from '../navigation/main-navigation'

import { CustomerAppLayoutContextProvider } from './customer-app-layout.context'
import * as styles from './customer-app-wrapper.styles'

type Props = {
  children: ReactNode
  section: Section
  sectionName?: ReactNode
  backTo?: string
}
export const CustomerAppWrapper = ({
  children,
  section,
  sectionName: defaultSectionName,
  backTo: defaultBackTo,
}: Props) => {
  const [sectionName, _setSectionName] = useState(defaultSectionName)
  const [backTo, setBackTo] = useState<string | null | undefined>(defaultBackTo)
  const [closePath, setClosePath] = useState<string | null>(null)
  const [isLivechatOpen] = useAtom(toggleLivechat)
  const isMobile = useBreakpoint('xsDown')

  useEffect(() => {
    _setSectionName(defaultSectionName)
  }, [defaultSectionName])

  const setSectionName = (newSectionName: string | null) => {
    if (newSectionName === null) {
      _setSectionName(defaultSectionName)
    } else {
      _setSectionName(newSectionName)
    }
  }

  const backButtonProps = !isNil(backTo)
    ? {
        to: backTo,
      }
    : undefined

  return (
    <CustomerApp section={section}>
      <AuthenticationGuard>
        <RemoteSectionsAvailabilityLoader />
        <CustomerAppLayoutContextProvider
          value={{ setSectionName, setBackTo, setClosePath }}
        >
          <Hidden xsDown>
            <MainNavigation />
          </Hidden>
          <div css={styles.section}>
            <div css={styles.sectionContent}>
              <Hidden smUp>
                <CustomerMobileAppBar
                  backButtonProps={backButtonProps}
                  closePath={closePath}
                  sectionName={sectionName}
                />
              </Hidden>
              <Hidden xsDown>
                <CustomerDesktopAppBar
                  backButtonProps={backButtonProps}
                  sectionName={sectionName}
                />
              </Hidden>
              <div
                css={styles.contentWrapper}
                data-testid="customer-app-container"
              >
                {children}
              </div>
              <Hidden smUp>
                <CustomerMobileNavigation />
              </Hidden>
            </div>
          </div>
          {isMobile ? isLivechatOpen && <LiveChat /> : <LiveChat />}
        </CustomerAppLayoutContextProvider>
      </AuthenticationGuard>
    </CustomerApp>
  )
}
