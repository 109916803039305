import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { duration } from '@twisto/styles'

export const link = (theme: Theme) => css`
  display: block;
  transition: all ${duration.complex}ms;
  clip-path: polygon(0 0, 85% 0, 88% 100%, 0% 100%);
  color: ${theme.palette.neutral['600']};
  cursor: pointer;
  text-decoration: none;

  .activeIcon {
    display: none;
  }

  :hover,
  :focus {
    color: ${theme.palette.neutral['700']};
    background-color: ${theme.palette.neutral[100]};

    .defaultIcon {
      display: none;
    }

    .activeIcon {
      display: block;
    }
  }
`

export const linkActive = (theme: Theme) => css`
  background-color: ${theme.palette.brand[100]};
  color: ${theme.palette.brand[500]};

  .defaultIcon {
    display: none;
  }

  .activeIcon {
    display: block;
  }

  :hover,
  :focus {
    color: ${theme.palette.brand[500]};
  }
`

export const nav = css`
  overflow: hidden;
  width: 224px;
  transition: width ${duration.complex}ms;
`

export const navCollapsed = css`
  width: 74px;
`
