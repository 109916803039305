import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const avatarSizes = { small: 32, normal: 40, medium: 48, bigger: 64 }

export const mainIconStyle = (theme: Theme, noBorder: boolean) => css`
  width: 100%;
  height: 100%;
  border-radius: ${styles.borderRadius.circle};
  border: ${noBorder ? '0' : '1px'} solid ${theme.palette.neutral[300]};
`
export const root = (theme: Theme) => css`
  position: relative;
  display: flex;
  border-radius: ${styles.borderRadius.circle};
  background: ${theme.palette.background.primary};
`

export type AvatarSizes = '32' | '40' | '48' | '64'
export const size = (px: AvatarSizes) => css`
  width: ${px}px;
  height: ${px}px;
  font-size: ${px}px;
`

export const noPlaceholderBackground = css`
  background: none;
`
export const image = css`
  font-size: ${styles.fontSize.xxxs};
`

export const subIcon = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  overflow: hidden;

  border-radius: ${styles.borderRadius.circle};
  right: -8px;
  bottom: -8px;
  height: 24px;
  width: 24px;
  font-size: 24px;
`

export const spacingRight = css`
  margin-right: ${styles.size[8]};
`

export const children = css`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
